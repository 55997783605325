import Header from "../Header";
import React, {useEffect, useState} from "react";

import {useDispatch} from "react-redux";
import { useParams} from "react-router-dom";
import {setNavProps} from "../../components/util/redux";






function Clients(props) {

    const { module,userId } = useParams();

    const dispatch = useDispatch();
    const [ready,setReady] = useState(false);


    useEffect(()=>{
        dispatch(setNavProps({module:module,type:"clients",activeClient:userId}));
        setReady(true);
    },[module])


    return (
        <div>
            {
                ready && (<Header id={'header-comp'} type="clients" module={module}/>)
            }

        </div>
    );
}

export default Clients;
