import React from "react";
import {connect, useDispatch} from "react-redux";

import {Tree, message, Button,Modal,Select,Popconfirm} from 'antd';
import {updateClientConf} from "./util/redux";


import {PlusOutlined,DeleteOutlined,EditOutlined} from '@ant-design/icons'
import {diff} from "deep-object-diff";

const {Option}=Select;
const _ = require('lodash');
const x = 3;
const y = 2;
const z = 1;
const gData = [];
const { confirm } = Modal;
const generateData = (_level, _preKey, _tns) => {
    const preKey = _preKey || '0';
    const tns = _tns || gData;

    const children = [];
    for (let i = 0; i < x; i++) {
        const key = `${preKey}-${i}`;
        tns.push({title: key, key});
        if (i < y) {
            children.push(key);
        }
    }
    if (_level < 0) {
        return tns;
    }
    const level = _level - 1;
    children.forEach((key, index) => {
        tns[index].children = [];
        return generateData(level, key, tns[index].children);
    });
};





const select = state => {

    let scripts;

    if ( state && state.hasOwnProperty("request") && state.request.hasOwnProperty("scripts") ){
        scripts=state.request.scripts.map(c=>c.id).sort((a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b))
    }else {
        scripts=state.request.scripts
    }

    return {
        darkMode: state.request.darkMode,
        activeClient: state.request.activeClient,
        clientConf: state.request.clientConf,
        rScripts:scripts,
    };
};

const TreeViewScripts=(props) => {


    const [tData,setTdata]=React.useState(gData);
    const [expandedKeys,setExpandedKeys]=React.useState([]);
    const [dragNodeAllowed,setDragNodeAllowed]=React.useState(false);
    const [treeData,setTreeData]=React.useState([]);

    const onDragEnter = info => {

        // expandedKeys
        setExpandedKeys(info.expandedKeys)


    };
    const loop = (data, key, callback) => {
        for (let i = 0; i < data.length; i++) {
            if (data[i].key === key) {
                return callback(data[i], i, data);
            }
            if (data[i].children) {
                loop(data[i].children, key, callback);
            }
        }
    };

    const onDrop = info => {

        const dropKey = info.node.key.split("|").slice(0, 2).join("|");
        const dragKey = info.dragNode.key;
        const dropPos = info.node.pos.split('-').slice(0, 2);
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);


        const data = _.cloneDeep(tData);

        // Find dragObject
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;

        });

        if (!info.dropToGap) {
            // Drop on the content
            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert ，
                item.children.unshift(dragObj);
            });

        } else {

            loop(data, dropKey, item => {
                item.children = item.children || [];
                // where to insert
                item.children.unshift(dragObj);
                // in previous version, we use item.children.push(dragObj) to insert the
                // item to the tail of the children
            });

        }

        setTdata(data)

    };

    const {scripts,clientConf,config,activeClient,rScripts,type}=props;


    const dispatch=useDispatch();

    const getScriptData = () => {
        let treeData = {};
        try {
            treeData = Object.keys(scripts).map(methode => {
                let clientScripts;
                try{
                   clientScripts=clientConf.config[config][type];

                }catch (e){
                    message.error("Could not load client script config - Error: " +e);
                }
                return {
                    title: methode, key: methode, children: scripts[methode].map(s => {
                        return {
                            title: s,
                            key: `${methode}|${s}`,
                            children: (clientScripts && clientScripts.hasOwnProperty(methode) && clientScripts[methode].hasOwnProperty(s))?clientScripts[methode][s].map(scriptName=>{
                                return {
                                    key: `${methode}|${s}|${scriptName}`,
                                    title:scriptName
                                }}) : []
                        }
                    })
                } //{title:"+ Add Script",blockDrag:true,key:`${methode}|${s}|add`}
            })

        } catch (e) {
            message.error("wrong format! Error:" + e);
        }
       setTdata( treeData);

    }


    React.useEffect(()=>{


        if (tData.length>0){
            console.log(tData);
            let tscripts={};

            tData.forEach(methode=>{

                let methodeObj={};
                methode.children.forEach(state=>{
                    methodeObj[state.key.split("|").pop()]=state.children.map(tscripts=>
                        tscripts.key.split("|").pop()
                    );
                })

                tscripts[methode.key]=methodeObj;



            });

            if (Object.keys(diff(clientConf.config[config][type],{...tscripts})).length>0) {


                dispatch(updateClientConf({module: config, subConfig: type, value: {...tscripts}}));
            }




            setTreeData(
                tData.map(methode=>{
                    return {key:methode.key,title:methode.title,children:methode.children.map(state=>{

                            return {
                                key:state.key,
                                title: <div ><span style={{marginRight:"10px"}}>{state.title}</span><Button size={"small"} onClick={(e) => {
                                    e.preventDefault();
                                    let scriptName=undefined;
                                    function callback(e){
                                        scriptName=e;


                                    }

                                    confirm({
                                        content:<Select placeholder={"Scriptname"} style={{width:"300px"} } showSearch optionFilterProp="children"  filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        } onSelect={callback} >
                                            {
                                                rScripts.map(script => (<Option key={script} id={script}>{script}</Option>))
                                            }
                                        </Select>,

                                        onOk:()=>{

                                            if (scriptName && scriptName.length>0){

                                                const data = _.cloneDeep(tData);
                                                loop(data, `${methode.title}|${state.title}`, item => {
                                                    item.children = item.children || [];

                                                    // where to insert ，
                                                    item.children.push({
                                                        key: `${methode.title}|${state.title}|${scriptName}`,
                                                        title:scriptName
                                                    });
                                                });

                                                setTdata(data)

                                            }else{
                                                message.error("Please enter a script name!");
                                            }
                                        }
                                        ,
                                        title:"Please enter the script name"
                                    })
                                }} icon={<PlusOutlined/>}/></div>,
                                children:state.children.map(scriptName=>{
                                    return {
                                        title:<div><span style={{marginRight:"10px"}}>{scriptName.title}</span>

                                            <Button
                                                size={"small"}
                                                onClick={()=>{
                                                    window.location.href="/scripts/"+scriptName.title;




                                                }}
                                                icon={ <EditOutlined />}
                                            />
                                            <Button
                                            size={"small"}
                                            onClick={()=>{
                                                confirm({
                                                    title:"Are you sure to delete this script?",
                                                    okText:"Yes",
                                                    cancelText:"No",
                                                    onOk:()=>{
                                                        const data = _.cloneDeep(tData);
                                                        loop(data, `${methode.title}|${state.title}|${scriptName.title}`, (item, index, arr) => {
                                                            arr.splice(index, 1);
                                                        });
                                                        console.log(data);
                                                        setTdata(data
                                                           );
                                                    }
                                                })



                                            }}
                                            icon={<DeleteOutlined />}
                                        /></div>,
                                        key:scriptName.key
                                    }
                                })
                            }})
                    }}))

        }




    },[tData])


    React.useEffect(()=>{
       getScriptData();

    },[])




   const allowDrop = (info) => {
        const pos = info.dropNode.key.split("|").length;

        if (pos < 2) {
            return false;
        } else if (dragNodeAllowed) {

            return true;
        } else {
            return false;
        }


    }

    const onDragStart = (info) => {

        if (info.node.pos.split("-").length < 4) {
            setDragNodeAllowed( false);
            message.error("You can not drag this element")
        } else if (info.node.props.blockDrag) {
            setDragNodeAllowed( false);
            message.error("You can not drag this element")
        } else {
            setDragNodeAllowed( true);
        }
    }





        return (
            ( treeData && treeData.length>0 )&&
            <Tree
                key={activeClient + "-" +config + "-scriptsEdit"}
                className="draggable-tree"
                draggable
                blockNode
                autoExpandParent={true}
                defaultExpandAll={true}
                allowDrop={allowDrop}
                onDragEnter={onDragEnter}
                onDragStart={onDragStart}
                onDrop={onDrop}
                treeData={treeData}
            />
        );
    }


export default connect(select,{updateClientConf})(TreeViewScripts)

