import React from 'react';
import { RedocStandalone } from 'redoc';
import { useParams} from "react-router-dom";

export const OpenApi = (props)=>{

    const params=useParams();
    console.log(params);
    return(
        <RedocStandalone specUrl= {`${window._env_.REACT_APP_API_URL}v1/openapi/${params.clientId}`}
                         options={{
                             licenceKey:'sadf',
                             exapandSingleSchemaField:true,
                             schemaExpansionLevel:'all',
                             jsonSampleExpandLevel:'all',
                             requiredPropsFirst:true,
                             expandDefaultServerVariables:true,
                             expandResponses:'all',
                             theme: { colors: { primary: { main: '#EF7721' } } },
                             defaultSampleLanguage:'JavaScript',
                             generateCodeSample:
                                 {languages:[{label:'JS',lang:'JavaScript'},{label:'NodeJS',lang:'Node.js'},{label:'Java',lang:'Java'},{label:'C#',lang:'C#'}]}
                         }}

        />
    )
}


