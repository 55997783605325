import React from "react";

import { AuthConsumer } from "./AuthProvider";

import ManyosSpinner from "../ManyosSpinner/ManyosSpinner";



export const PrivateRoute = ({ children }) => {



    return(
        <AuthConsumer>
            {({ isAuthenticated, signinRedirect }) => {


                if (!!children && isAuthenticated()) {
                    return children
                } else {

                    signinRedirect();
                    return <ManyosSpinner/>;
                }
            }}
        </AuthConsumer>

    )
}
