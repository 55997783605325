import React from 'react';



import Navigator from './views/Navigator';
import {SCRoutes as Routes} from './routes';

import { Layout,Typography } from 'antd';
import ErrorHandler from "./components/util/ErrorHandler";




const { Footer, Sider, Content } = Layout;





function Copyright() {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <a color="inherit" href="https://manyos.it/">
          https://www.manyos.it
        </a>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
  );
}


export default function Smileconnect(props) {

    const [openApi, setOpenApi] = React.useState(() => {
        const re = new RegExp(window._env_.REACT_APP_GUI_URL)
        try {
            const url = window.location.href.replace(re, "").split("/")[0]
            if (url.toUpperCase() === "OPENAPI" && window._env_.REACT_APP_OPENAPI !== "DISABLED") {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log(e)
            return false
        }
        return false
    })


    if (openApi) {
        return (
            <Layout>
                <ErrorHandler>

                    <Layout>

                        <Content><Routes {...props}/></Content>
                        <Footer> <Copyright/></Footer>
                    </Layout>
                </ErrorHandler>
            </Layout>)

    } else {


        return (
            <Layout>
                <ErrorHandler>

                    <Sider theme={"light"} width={350} style={{minHeight: window.innerHeight}}>
                        <Navigator/>
                    </Sider>
                    <Layout>

                        <Content><Routes {...props}/></Content>
                        <Footer> <Copyright/></Footer>
                    </Layout>
                </ErrorHandler>
            </Layout>


        )
    }
}




