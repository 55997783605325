import Header from "../Header";
import React, {useContext, useEffect,useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {setNavProps} from "../../components/util/redux";
import {getCustomMappings, getMappings} from "../../components/util/redux/asyncActions";
import {useDispatch} from "react-redux";
import {AuthContext} from "../../components/Auth/AuthProvider";



function Mapping() {
    const { module } = useParams();

    const [ready,setReady] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userManager = useContext(AuthContext);


    useEffect(()=>{

        dispatch(setNavProps({
            module: module,
            type: "mapping",
            activeClient: undefined
        }));
        dispatch(getMappings({userManager, navigate}));
        dispatch(getCustomMappings({userManager, navigate}));
        setReady(true);

    },[module])





    return (
        <div>
            {
                ready && (<Header id={'header-comp'} type="mapping" module={module}/>)
            }


        </div>
    );
}


export default Mapping;
