import {Button, Form, Input, Select} from "antd";
import {setClientConfig} from "./util/redux/asyncActions";
import Table from "./ManyosTable";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {updateClientConf} from "./util/redux";
const {Option} = Select;

const TableFields = (props) => {
    const {mapping, type, module, loading, formFields, clientConf, activeClient,selMapping,form,customForms} = props;
    const dispatch=useDispatch();
    const [hasSelected, setHasSelected] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const [addForm] = Form.useForm();

    const onSelectChange = selectedRowKeys => {

        setSelectedRowKeys(selectedRowKeys);
    };
    const [sortedFields,setSortedFields]=useState([]);

    useEffect(()=>{
        if (formFields){
            setSortedFields(Object.keys(formFields).map(f=>{return {id:formFields[f].fieldId,value:formFields[f].name,label:formFields[f].name}}).sort((a, b) => (isNaN(a.label) && isNaN(b.label) ? (a.label || '').localeCompare(b.label || '') : a.label - b.label)))
        }


    },[formFields])

    const handleSave = (record, values) => {




        let newArray = clientConf.config[selMapping]["fields_"+form].map((row, k) => {
            if (k == record.key) {
                return values.fieldName;
            }
            return row
        })
        console.log({module:selMapping,mapType:"fields_"+form,value:newArray});

        dispatch(updateClientConf({module:selMapping,subConfig:"fields_"+form,value:newArray}));
    }

    useEffect(() => {
        setHasSelected(selectedRowKeys.length > 0);
    }, [selectedRowKeys])

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const ChangeSelect = (props) => {
        const inputRef = useRef(null);
        const [selOptions,setSelOptions] = React.useState(sortedFields.slice(0,30));
        useEffect(() => {

           // inputRef.current.focus();

        }, []);
        return (
            <div onMouseLeave={props.onLeave} style={{paddingBottom:"5px"}}>
            <Select
                showSearch

                placeholder="select a field"

                onSearch={(input)=>{
                    if (input.length>0){
                        setSelOptions(sortedFields.filter(f=>f.label.toLowerCase().indexOf(props.value.toLowerCase())>=0||f.label.toLowerCase().indexOf(input.toLowerCase()) >= 0).slice(0,30))
                    }else{
                        setSelOptions([...sortedFields.filter(f=>f.label.toLowerCase().indexOf(props.value.toLowerCase())>=0),...sortedFields.slice(0,30)])
                    }

                }
                }
                filterOption={false}
                optionLabelProp="label"
                ref={inputRef}
                onSelect={props.handlesave}
                {...props}
                options={selOptions}
            >
                {/*
                    formFields &&
                    Object.keys(formFields).map(f => {
                        return {fieldId: formFields[f].fieldId, name: formFields[f].name}
                    }).sort((a, b) => (isNaN(a.name) && isNaN(b.name) ? (a.name || '').localeCompare(b.name || '') : a.name - b.name)).map(f => (
                        <Option key={f.fieldId} value={f.name}>{f.name}</Option>
                    ))
                */}

            </Select></div>)

    }


    return (
        <>
            <Form
                form={addForm}
                name="customized_form_controls"
                layout="inline"
                style={{marginLeft: "15px"}}
                onFinish={(values) => {
                    const clientModConf={...clientConf.config[selMapping]};

                    const newFields=[...clientModConf["fields_"+form],values.fieldName]


                    dispatch(updateClientConf({module:selMapping,subConfig:"fields_"+form,value:newFields}));
                    addForm.resetFields();
                }}
                initialValues={{
                    field: {
                        fieldName: undefined,

                    },
                }}
            >
                <Form.Item
                    name="fieldName"
                    label="Form Name"
                    rules={[{required: true, message: 'Please select the Form Field!'}]}
                >
                    <Select
                        showSearch
                        style={{width: 500, float: "right", margin: "0 15px 15px 0"}}
                        placeholder="select a field"
                        optionFilterProp="children"

                    >
                        {
                            formFields &&
                            Object.keys(formFields).map(f => {
                                let apiName
                                try {


                                    apiName = mapping["cmdbobject_"+form].filter(m => formFields[f].name === m.oldName)[0].newName
                                } catch (e) {
                                    apiName = "undefined"
                                }
                                return {fieldId: formFields[f].fieldId, name: formFields[f].name, apiName:apiName}
                            }).sort((a, b) => (isNaN(a.name) && isNaN(b.name) ? (a.name || '').localeCompare(b.name || '') : a.name - b.name)).map(f =>
                                (                                <Option key={f.fieldId} value={f.name}>{f.name + " - " + f.apiName} </Option>)
                            )
                        }

                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button

                        onClick={() => {
                            addForm.resetFields();
                        }}
                    >
                        Cancel
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button

                        onClick={()=>{

                            let allFields=[]
                            formFields &&
                            Object.keys(formFields).map(f => {
                                let apiName
                                try {


                                    apiName = mapping["cmdbobject_"+form].filter(m => formFields[f].name === m.oldName)[0].newName
                                } catch (e) {
                                    apiName = "undefined"
                                }
                                return {fieldId: formFields[f].fieldId, name: formFields[f].name, apiName:apiName}})
                                .filter(a=>a.apiName!==undefined && a.apiName!=="undefined").forEach(field=>


                                allFields.push(field.name)

                            )



                            const clientModConf={...clientConf.config[selMapping]};





                            const newFields=[...new Set([...clientModConf["fields_"+form],...allFields])]

                            console.log()

                            dispatch(updateClientConf({module:selMapping,subConfig:"fields_"+form,value:newFields}));
                            addForm.resetFields();


                        }}


                    >
                        Add all
                    </Button>
                </Form.Item>
            </Form>

            <Button style={{margin: "0 15px 15px 15px"}}
                    disabled={!hasSelected}
                    onClick={() => {

                        const newValues = clientConf.config[selMapping]["fields_"+form].filter((r, i) => selectedRowKeys.indexOf(i) < 0)

                        dispatch(updateClientConf({
                            module: selMapping,
                            subConfig:"fields_"+form,
                            value: newValues
                        }));
                        setSelectedRowKeys([]);

                    }}
            >
                Delete Row
            </Button>
            <Table
                style={{padding: "0 15px 0 15px"}}
                pagination={false}

                rowSelection={rowSelection}
                dataSource={
                    clientConf && clientConf.config && clientConf.config[selMapping] && clientConf.config[selMapping].hasOwnProperty("fields_"+form) && clientConf.config[selMapping]["fields_"+form].map((entry, i) => {

                            let apiName
                            try {

                                apiName = mapping["cmdbobject_"+form].filter(m => entry === m.oldName)[0].newName
                            } catch (e) {
                                apiName = "undefined"
                            }

                            return {
                                key: i,
                                fieldName: entry,
                                apiName: apiName
                            }
                        }
                    )

                }


                columns={
                    [{
                        title: 'Form Name',
                        dataIndex: 'fieldName',
                        key: 'fieldName',
                        extra: {editable: true, FormComp: ChangeSelect, handleSave: handleSave}
                    },
                        {title: 'API Name', dataIndex: 'apiName', key: 'apiName'}]
                }
            >

            </Table>
        </>)
}
export default TableFields
