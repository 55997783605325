import {Mapping} from "./views/mapping";
import {Clients} from "./views/clients";
import {Scripts} from "./views/scripts";
import {Metrics} from "./views/metrics";
import Edit from "./views/edit";
import React, {useContext} from "react";
import {Route, Routes, Navigate, useNavigate} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {Callback} from "./components/Auth/Callback";
import {Logout} from "./components/Auth/Logout";
import {LogoutCallback} from "./components/Auth/LogoutCallback";
import {PrivateRoute} from "./components/Auth/PrivateRoute";
import {SilentRenew} from "./components/Auth/SilentRenew";
import {AuthContext} from "./components/Auth/AuthProvider";

import {OpenApi} from "./components/OpenApi"

export const SCRoutes = (props) => {


    const userManager = useContext(AuthContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (

        <Routes>

            <Route path="/signin-oidc" element={<Callback/>}/>
            <Route path="/logout" element={<Logout/>}/>
            <Route path="/logout/callback" element={<LogoutCallback/>}/>
            <Route path="/silentrenew" element={<SilentRenew/>}/>
            {(window._env_.REACT_APP_OPENAPI !== "DISABLED") &&
            <Route exact={true} path="/openapi/:clientId" element={<OpenApi/>}/>}
            <Route path="/mapping/:module"
                   element={
                       <PrivateRoute>
                           <Mapping/>
                       </PrivateRoute>
                   }
            />
            <Route path="/edit/:module" element={
                <PrivateRoute type="edit">
                    <Edit/>
                </PrivateRoute>

            }/>
            <Route path="/clients/:userId/:module"
                   element={
                       <PrivateRoute>
                           <Clients/>
                       </PrivateRoute>

                   }
            />
            <Route path="/scripts/:scriptId"
                   element={
                       <PrivateRoute>
                           <Scripts/>
                       </PrivateRoute>
                   }
            />
            <Route path="/metrics/:metric"
                   element={
                       <PrivateRoute>
                           <Metrics/>
                       </PrivateRoute>
                   }
            />
            <Route path="/" element={<Navigate to="/mapping/incident" replace/>}/>


        </Routes>

    );
}
