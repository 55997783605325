import Header from "../Header";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setNavProps} from "../../components/util/redux";
import {getScript} from "../../components/util/redux/asyncActions";
import {AuthContext} from "../../components/Auth/AuthProvider";




function Scripts(props) {


    const { module,scriptId } = useParams();

    const dispatch = useDispatch();
    const userManager = useContext(AuthContext);
    const [ready,setReady] = useState(false);

    useEffect(()=>{
        dispatch(getScript({scriptId,userManager}));
        dispatch(setNavProps({type:"scripts",scriptName:scriptId}))
        setReady(true);
    },[module])




    return (
        <div>
            {
                ready && (<Header id={'header-comp'} type="scripts" module={module}/>)
            }

        </div>
    );
}


export default Scripts;
