import React from 'react';


import Editor, {useMonaco} from "@monaco-editor/react";
import {DownloadOutlined} from "@ant-design/icons";

import { Upload, message,Progress,Modal,Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';


const { Dragger } = Upload;





function getFormattedTime() {
    var today = new Date();
    var y = today.getFullYear();
    // JavaScript months are 0-based.
    var m = today.getMonth() + 1;
    var d = today.getDate();
    var h = today.getHours();
    var mi = today.getMinutes();
    var s = today.getSeconds();
    return y + "-" + m + "-" + d + "-" + h + "-" + mi + "-" + s;
}
const EditConf = (props) => {

    const {mapping,module,clients,value,onChange} = props;

    const [load,setLoad]=React.useState({loaded:true, fileName:"",progress:100});



    let filename = getFormattedTime()+ "_"+module+".json";
    let contentType = "application/json;charset=utf-8;";


    const fileprops = {
        name: 'file',
        multiple: false,
        accept:".json,application/json",
        onChange(info) {

            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
                if (!info.file.type ) {
                    return
                }
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {

                    onChange(JSON.stringify(JSON.parse(event.target.result),0,2));
                });

                reader.addEventListener('progress', (event) => {
                    if (event.loaded && event.total) {
                        const percent = (event.loaded / event.total) * 100;
                        if (percent<100){
                            setLoad({loaded:false,fileName:load.fileName,progress: Math.round(percent)})
                        }else{

                            setLoad({loaded:true,fileName:load.fileName,progress: Math.round(percent)})
                            message.success(`${info.file.name} file was loaded successfully.`);
                        }


                    }
                });
                reader.readAsText(info.file);
            }

        },
        beforeUpload: file => {

            setLoad({loaded:false,fileName: file.name,progress: 0})
            this.setState(state => ({
                fileList: [...state.fileList, file],
            }));
            return false;
        },

    };

    return (
        <>
            {(load && !load.loaded) ? (
                <div>
                    <span>{"Loading " + load.fileName}</span>
                    <Progress type={"circle"} percent={load.progress}/>
                </div>

            ):(
                <div style={{width:"600px",height:"200px",marginLeft:"auto",marginRight:"auto"}}>
                    <Button
                        style={{float:"left",marginRight:"20px"}}
                        href={'data:' + contentType + ',' + encodeURIComponent(value)}
                        download= {filename}
                        target = "_blank"
                        icon={<DownloadOutlined />}
                    >Download</Button>
                    <div  style={{float:"left", width:"400px"}}>
                        <Dragger {...fileprops}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                {`Upload a new ${module} config file here`}
                            </p>
                        </Dragger>
                    </div>
                </div>
            )}



            <Editor
                defaultValue={value}
                value={value}

                defaultLanguage={"json"}
                onChange={onChange}
                height={"100vh"}

            />
        </>



    )
}

export default EditConf

