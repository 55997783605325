

 export let OidcSettings = {

    authority: window._env_.REACT_APP_SSO_URL,
    client_id: window._env_.REACT_APP_CLIENT_ID,
    client_secret:   (window._env_.REACT_APP_CLIENT_SECRET && window._env_.REACT_APP_CLIENT_SECRET.length>0) ? window._env_.REACT_APP_CLIENT_SECRET : undefined,
    redirect_uri: window._env_.REACT_APP_GUI_URL+"signin-oidc",
    response_type: window._env_.REACT_APP_OIDC_RESPONSE_TYPE,
    scope:  window._env_.REACT_APP_OIDC_SCOPE,
    monitorSession: false,
    silent_redirect_uri:window._env_.REACT_APP_GUI_URL+"silentRenewFrame.html",
    automaticSilentRenew:true,
    extraQueryParams:{checkLoginIframe:false},
    accessTokenExpiringNotificationTime:window._env_.REACT_APP_TOKEN_REFRESHTIME,
    post_logout_redirect_uri:window._env_.REACT_APP_GUI_URL+"logout/callback",
    webAuthResponseType:'id_token token',

};




export const  METADATA_OIDC = {
    issuer: window._env_.REACT_APP_SSO_URL,
    jwks_uri: window._env_.REACT_APP_SSO_URL + "/protocol/openid-connect/certs",
    authorization_endpoint: window._env_.REACT_APP_SSO_URL + "/protocol/openid-connect/auth",
    token_endpoint: window._env_.REACT_APP_SSO_URL + "/protocol/openid-connect/token",
    userinfo_endpoint: window._env_.REACT_APP_SSO_URL + "/protocol/openid-connect/userinfo",
    end_session_endpoint: window._env_.REACT_APP_SSO_URL + "/protocol/openid-connect/logout",
    check_session_iframe: window._env_.REACT_APP_SSO_URL + "/protocol/openid-connect/login-status-iframe.html",
    introspection_endpoint: window._env_.REACT_APP_SSO_URL + "/protocol/openid-connect/token/introspect"
};
