import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, connect} from "react-redux";

import {Tabs, Select, Input, Form, Button, Empty, Modal, message} from "antd"
import TableFields from "./TableFields";
import TableFieldsCMDB from "./TableFieldsCMDB";
import TableConstants from "./TableConstants";

import TreeViewScripts from "./TreeViewScripts";
import BaseQuery from "./BaseQuery";

import {getClientHooks, smileConnectAPIcall} from "./util/redux/asyncActions";
import {getClient, getFormFields, getCustomMappings, setClientConfig,getCustomForms} from "./util/redux/asyncActions";
import submenu from "../config/submenu.json";
import {addCustomForm,removeCustomForm} from "./util/redux";
import {AuthContext} from "./Auth/AuthProvider";
import {useNavigate} from "react-router-dom";
import ManyosSpinner from "./ManyosSpinner/ManyosSpinner";



const {confirm} = Modal;

const {TabPane} = Tabs;
const {Option} = Select;

function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }


const select = (state) => {
    return {
        mapping: state.request.mapping,
        type: state.request.type,
        loading: state.request.clientConfLoading,
        clientConf: state.request.clientConf,
        cmdbForms:state.request.cmdbForms,
        activeClient: state.request.activeClient,
        clientHooks:state.request.clientHooks,
        formFields:state.request.formFields,
        customForms:state.request.customForms,
        mappingCustom:state.request.mappingCustom
    }
}


const CustomForms = (props) => {
    const {mapping, type, module, loading, clientConf, activeClient, codeView,cmdbForms,clientHooks,formFields,customForms,mappingCustom} = props;
    const dispatch = useDispatch();
    const userManager = useContext(AuthContext);
    const navigate=useNavigate();


    const [customConfig, setCustomConfig] = React.useState(
        []
    );
    useEffect(() => {
        if (!mappingCustom || (mapping &&  Object.keys(mappingCustom).length === 0)) {

            dispatch(getCustomMappings({userManager,navigate}));
        }
    }, [])
    useEffect(() => {

        if (Object.keys(clientConf).length === 0) {
            dispatch(getClient({clientId: activeClient,userManager,navigate}));
        }

    }, [])
    useEffect(() => {

            dispatch(getCustomForms({userManager,navigate}));


    }, [module]);

    useEffect(() => {

        let tabs = []
        if (clientConf && clientConf.hasOwnProperty("config")) {
            tabs = Object.keys(clientConf.config).filter(c => c.indexOf("custom_") >= 0).map(config => {


                return {

                    [config]: {
                        "name": config.replace(/custom_/g, ""),
                        "tabs": [
                            {
                                "name": "Base Query",
                                "table": "BaseQuery",
                                "config": config,
                                "form": config.replace(/custom_/g, "")
                            },
                            {
                                "name": "Fields",
                                "table": "TableFields",
                                "config": config,
                                "form": config.replace(/custom_/g, "")
                            },
                            {
                                "name": "Constants",
                                "table": "TableConstants",
                                "config": config,
                                "form": config.replace(/custom_/g, "")
                            },
                            {
                                "name": "Scripts",
                                "table": "TreeViewScripts",
                                "config": config,
                                "form": config.replace(/custom_/g, ""),
                                "scripts": {
                                    "GET": [
                                        "preMapping",
                                        "postMapping"

                                    ],
                                    "PUT": [
                                        "preMapping",
                                        "postMapping",
                                        "afterExecution"
                                    ],
                                    "POST": [
                                        "preMapping",
                                        "postMapping",
                                        "afterExecution"
                                    ]
                                }
                            }
                            ]}


                }
            })

        }

        if (JSON.stringify(tabs)!==JSON.stringify(customConfig)){
            setCustomConfig(tabs);
        }




    }, [clientConf])




    const [selMapping, setSelMapping] = React.useState(undefined);
    const [form, setForm] = React.useState(undefined);


    useEffect(()=>{
        if (customConfig && Array.isArray(customConfig) && customConfig.length>0) {


            try{
                setSelMapping( Object.keys(customConfig[0])[0])
                setForm(customConfig[0][Object.keys(customConfig[0])[0]].tabs[0].form)
            }catch(e){
                message.error(e);
            }

        }else{
            setSelMapping(undefined)
            setForm(undefined)
        }
    },[customConfig])


    const onEdit = (targetKey, action) => {

        let newForm;

        function callback(e) {

            newForm = e;
        }




        if (action == "add" ) {
            confirm({
                title: "select the form",
                content: <Select
                    showSearch
                    style={{width: 300, float: "right", margin: "0 15px 15px 0"}}
                    placeholder="select a field"
                    optionFilterProp="children"
                    onSelect={callback}
                >
                    {customForms.map(f=><Select.Option value={f} key={f}>{f}</Select.Option>)}
                </Select>,
                onOk: () => {
                    if (newForm && newForm.length > 0) {
                        dispatch(addCustomForm({form:newForm}));
                    } else {
                        message.error("Please select a form name!");
                    }
                }
            })
        }



        if (action==="remove" ){
            confirm({
                title: "Are you sure?",
                content: "Do you really want to remove this config?",
                onOk: () => {
                    dispatch(removeCustomForm({form:targetKey}));
                },
                okText:"Yes",
                cancelText:"No"
            })

        }



    }




    const [fields, setFields] = React.useState();




    useEffect(() => {
        if (!fields && form) {

            const resp = smileConnectAPIcall({
                url: window._env_.REACT_APP_API_URL + "v1/AppConfig/forms/" + form + "/fields",
                requestOptions: {method: "GET", "content-type": "application/json"},userManager,navigate
            }).then(
                f => {

                    setFields(f.data);
                }
            );

        }

    }, [form]);




    useEffect(() => {


            if (form) {


                dispatch(getFormFields({ form,userManager,navigate}))
            } else {
                try {


                    dispatch(getFormFields({form: customConfig[0][Object.keys(customConfig[0])[0]].tabs[0].form,userManager,navigate}))
                } catch (e) {
                    console.log(e)
                }


            }


    }, [form, module, selMapping,customConfig]);





    return (
        (loading)
            ? (<ManyosSpinner/>)
            : (
                <Tabs
                    className={"sub-tabs-right"}
                    tabPosition={'top'}
                    type={ "editable-card" }
                    defaultActiveKey={0}
                    onEdit={onEdit}
                    style={{height: '100%', width: '100%', backgroundColor: "white"}}
                    onChange={async (formName) => {

                        try {
                            setFields(undefined);

                                const conf = customConfig.find(e=> e.hasOwnProperty("custom_"+formName));




                                    setSelMapping("custom_"+formName);
                                    setForm(formName);


                                    smileConnectAPIcall({
                                        url: window._env_.REACT_APP_API_URL + "v1/AppConfig/forms/" + formName + "/fields",
                                        requestOptions: {method: "GET", "content-type": "application/json"},
                                        userManager,navigate
                                    }).then(formFields=>setFields(formFields.data));







                        } catch (e) {
                            console.log(e)
                        }

                    }

                }
                >
                    {
                        customConfig && customConfig.map((tab,k)=>{

                            return Object.keys(tab).map((e,i)=>{

                            return(
                                <TabPane
                                    closable={true}
                                    tab={tab[e].name}
                                    key={e.replace(/custom_/,"")}
                                >
                                    <Tabs
                                        className={"sub-tabs-right"}
                                        tabPosition={'top'}
                                        type={ "card" }
                                        defaultActiveKey={0}
                                        onEdit={onEdit}
                                        style={{height: '100%', width: '100%', backgroundColor: "white"}}

                                    >
                                        {



                                                      tab[e].tabs.map((item, i) => {

                                                        try {
                                                            let comp;

                                                            switch (item.table) {
                                                                case "TableFields":
                                                                    comp = <TableFields {...props} mapping={mappingCustom} customForms selMapping={selMapping} formFields={formFields} tab={item.table}
                                                                                        />
                                                                    break;
                                                                case "TableConstants":
                                                                    comp = <TableConstants {...props} tab={item.table} formFields={formFields} selMapping={selMapping}  />
                                                                    break;
                                                                case "BaseQuery":

                                                                    comp = <BaseQuery key={activeClient + "-" + e} tab={item.table} module={selMapping}
                                                                                      formFields={fields} form={form} config={selMapping}/>
                                                                    break;
                                                                case "TreeViewScripts":
                                                                    comp = <TreeViewScripts {...props} key={activeClient + "scr-" + e} tab={item.table}  config={selMapping}
                                                                                            scripts={item.scripts} type={"scripts"}/>
                                                                    break;
                                                                default:
                                                                    comp = <Empty/>
                                                            }
                                                            return (<TabPane
                                                                closable={false}
                                                                tab={item.name}
                                                                key={i}
                                                            >

                                                                {comp}


                                                            </TabPane>)
                                                        } catch (e) {
                                                            console.log(e)}


                                                     })

                                        }


                                    </Tabs>

                                </TabPane>
                            )})

                        })


                    }
                </Tabs>
               )
    )
}


export default connect(select)(CustomForms);
