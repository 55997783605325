import React, {useContext} from 'react';
import {connect,useDispatch} from "react-redux";

import CodeViewer from "../../components/CodeViewer";
import {DownloadOutlined} from "@ant-design/icons";

import { Upload, message,Progress,Modal,Button } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {getMappings,getCustomMappings} from "../../components/util/redux/asyncActions";
import {getClients} from "../../components/util/redux/asyncActions";
import {importFile, updateClientConf} from "../../components/util/redux";
import {AuthContext} from "../../components/Auth/AuthProvider";
import {useNavigate} from "react-router-dom";

const { Dragger } = Upload;
const _ = require("lodash");



const select = (state)=>{
    return {
        mapping:state.request.mapping,
        type:state.request.type,
        loading:state.request.mappingLoading,
        clients:state.request.clients,
        mappingCustom:state.request.mappingCustom

    }
}

const EditConf = (props) => {

    const {mapping,module,clients,mappingCustom} = props;
    const dispatch = useDispatch()

    const userManager = useContext(AuthContext);
    const navigate=useNavigate();


    React.useEffect(()=>{
        if (Object.keys(mapping).length === 0 && clients.length ===0){
            dispatch(getMappings({userManager,navigate}));
            dispatch(getClients({userManager,navigate}));
            dispatch(getCustomMappings({userManager,navigate}));
        }

    },[])

    React.useEffect(()=>{
        if (module=="mapping")
            setVal(JSON.stringify(mapping,0,2))
        if (module=="clients")
            setVal(JSON.stringify(clients,0,2))
        if (module=="mappingCustom")
            setVal(JSON.stringify(mappingCustom,0,2))
    },[mapping,clients,mappingCustom])

    const [val,setVal]= React.useState(
        ()=>{
            if (module=="mapping")
                return JSON.stringify(mapping,0,2)
            if (module=="clients")
                return JSON.stringify(clients,0,2)
            if (module=="mappingCustom")
                return JSON.stringify(mappingCustom,0,2)
        }

    );
    const updateStore=React.useCallback(
        _.debounce(q => {

            try{
                const newConf = JSON.parse(q);
                if (module === "clients") {
                    if(Array.isArray(newConf)){
                        dispatch(importFile({type:"clients",value:newConf}))
                    }else{
                        dispatch(importFile({type:"client",value:newConf}))
                    }

                }else if(module==="mapping"){
                    dispatch(importFile({type:"mapping",value:newConf}))
                }else if(module==="mappingCustom"){
                    dispatch(importFile({type:"mappingCustom",value:newConf}))
                }

            }catch(e){
                message.error(" " + e)
            }


        },1000)

        ,[])

    return (
        <>

            (mappings || clients || mappingCustom) &&
           <CodeViewer
                value={val}
                module={module}
                onChange={(e)=> {
                    setVal(e)

                    updateStore(e)

                }}

           />
    </>



    )
}

export default connect(select)(EditConf)

