import React from "react";
import { AuthConsumer } from "./AuthProvider";
import ManyosSpinner from "../ManyosSpinner/ManyosSpinner";

export const Callback = () => (
    <AuthConsumer>
        {({ signinRedirectCallback }) => {



            try{

                signinRedirectCallback();

            }catch(e){

                console.log(e);
            }

            return                 <ManyosSpinner />

        }}
    </AuthConsumer>
);
