import React, {useContext} from 'react';

import {Tabs,Switch,Dropdown,Button,PageHeader,Menu,message,Alert,Modal,Table} from 'antd';

import { LogoutOutlined,DownOutlined,SaveOutlined } from '@ant-design/icons';

import {useDispatch,connect} from "react-redux";
import submenu from "../config/submenu.json";

import {setDarkMode, setCodeView, setNavProps, updateClientConf, updateEventConf,updateMapping,updateCustomMapping} from "../components/util/redux";
import {saveAction, setControlMetrics} from "../components/util/redux/asyncActions";
import UserDetails from "./clients/UserDetails.js";
import MappingDetails from "./mapping/MappingDetails";
import Edit from "./edit/edit";
import CodeViewer from "../components/CodeViewer";

import ScriptDetails from "./scripts/ScriptDetails";
import {AuthContext} from "../components/Auth/AuthProvider";
import MetricDetails from "./metrics/MetricDetails";



const {TabPane} = Tabs;

const select = state => {

    return {
        darkMode: state.request.darkMode,
        codeView: state.request.codeView,
        activeClient:state.request.activeClient,
        scriptConf:state.request.scriptConf,
        scriptName:state.request.scriptName,
        module: state.request.module,
        unsaved: state.request.unsaved,
        type: state.request.type,
        mapping:state.request.mapping,
        clientConf:state.request.clientConf,
        clientHooks:state.request.clientHooks,
        saveLoading:state.request.saveLoading,
        dismissed:state.request.dismissed,
        importClient:state.request.importClient,
        importClients:state.request.importClients,
        importMapping:state.request.importMapping,
        importMappingCustom:state.request.importMappingCustom,
        importType:state.request.importType,
        mappingCustom:state.request.mappingCustom
    };
};



 const Header=(props) =>{



  const {  module,type,onCodeViewChange,darkMode,codeView,activeClient,userId,unsaved,metric,mapping,mappingCustom,clientConf,clientHooks,saveLoading,dismissed,importClients,importClient,importMapping,importMappingCustom,importType,scriptName,scriptConf} = props;


    const dispatch=useDispatch();
     const userManager = useContext(AuthContext);
    const handleChange =  event => {

        dispatch(setCodeView({codeView:event}))
};
    const handleChangeTheme =  event => {
        dispatch(setDarkMode({darkMode:event}))

    };

     const prevUnsavedRef = React.useRef();
     React.useEffect(() => {
         prevUnsavedRef.current = unsaved;
     });

     const prevUnsaved = prevUnsavedRef.current;


  function handleMenuClick(e) {
      if (e.key==="logout"){
          window.location.replace("/logout");
      }else{
          console.log('click', e);
      }
  }

React.useEffect(()=>{


        if (!unsaved && prevUnsaved && !dismissed){
                message.success("Saved successfully!");
        }

     },[unsaved])

     const [isModalVisible, setIsModalVisible] = React.useState(false);
     const handleOk = () => {
         setIsModalVisible(false);
     };

     const handleCancel = () => {
         setIsModalVisible(false);
     };

     const [usageData,setUsageData] = React.useState([]);

     React.useEffect(()=>{

         if (scriptConf && scriptConf.hasOwnProperty("usage") ){
             setUsageData(scriptConf.usage.map((u,i)=>{return {...u,modul:u.key,key:i}}));
         }
     },[scriptConf])


     return (
            <>
                <Modal title="Usage details" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                    <Table
                        columns={[
                            {title:'Client',dataIndex:'client', key:'client'},
                            {title:'Modul',dataIndex:'modul', key:'modul'},
                            {title:'Operation',dataIndex:'operation', key:'operation'},
                            {title:'Type',dataIndex:'scriptType', key:'scriptType'},

                        ]}
                        dataSource={ usageData}
                    />
                </Modal>

          <PageHeader
              className={"rightHeader"}
              ghost={false}
                title={
                    <>
                    <Button  icon={<SaveOutlined />}  loading={saveLoading}
                             onClick={() =>
                             {
                                 if (type=="clients"){
                                     dispatch(saveAction({type,activeClient,config:{client:clientConf,events:clientHooks},userManager}))
                                 }else if (type=="mapping"){
                                     dispatch(saveAction({type,config:{mapping,mappingCustom},userManager}))
                                 }else if (type=="edit"){

                                     dispatch(saveAction({type,importType,config:(importType==="clients")?importClients:((importType==="client")?importClient:((importType==="mapping")?importMapping:importMappingCustom)),userManager}))
                                 }else if (type=="scripts"){

                                     dispatch(saveAction({type,scriptName,config:scriptConf,userManager}))
                                 }else if(type=="metrics"){

                                     dispatch(setControlMetrics({metric}))
                                 }


                             }
                             } disabled={!unsaved} style={{marginRight:"10px"}}>Save</Button>
                        {(type=="clients" && module)
                            ?(activeClient + ((!codeView) && " / " +  submenu[type][module].name || " / code" ))
                            :(type=="mapping")
                                ? "Field Mapping / " + submenu[type][module].name
                                : (type=="scripts")?(<>{scriptName}{(scriptConf.usage && scriptConf.usage.length>0) && <Alert
                                    style={{float:"right",marginLeft :"40px",marginRight :"auto"}}
                                    message="Warning"
                                    description={<>This script is used by {scriptConf.usage.length}  client(s)! <a onClick={()=>{
                                        setIsModalVisible(true);}
                                    }>Details</a> </>}
                                    type="warning"
                                    showIcon

                                />}</>): (type=="metrics")? (<>{"Metric: "+ metric}</>):(null)}
                    </>
                }

              extra={[
                <div key="1" style={{display:"inline-block",color:"white"}}>{"Code View " }<Switch checked={codeView} onChange={handleChange}/></div>,
                <Button key="2" href="https://smileconnect.manyosdocs.de" target="new">Docs</Button>,
                <Dropdown
                    key="3"
                    overlay={
                      <Menu onClick={handleMenuClick}>
                          {/*<li key="darkmode" class="ant-dropdown-menu-item">
                               <Switch checked={darkMode} onChange={handleChangeTheme}/> Dark Mode
                          </li>*/}
                        <Menu.Item key="logout" icon={<LogoutOutlined />}>
                          Logout
                        </Menu.Item>


                      </Menu>
                    }
                >
                  <Button>
                    More <DownOutlined />
                  </Button>
                </Dropdown>
              ]}
          >
              {(!codeView || type==="edit" )


                  ?(
                      <Tabs
                          tabPosition={'top'}
                          defaultActiveKey={module}

                          style={{ height: '100%',width:'100%' }}
                          className={"top-tabs-right"}
                          onChange={(key)=>{

                              dispatch(setNavProps({activeClient,type,module:key}))
                              if (type=="clients"){
                                  window.history.replaceState(null, "SMILEconnect-GUI", "/"+type+"/"+activeClient+"/"+key)
                              } else if (type=="mapping"){
                                  window.history.replaceState(null, "SMILEconnect-GUI", "/"+type+"/"+key)
                              }else if (type=="edit"){
                                  window.history.replaceState(null, "SMILEconnect-GUI", "/"+type+"/"+key)
                              }
                              else if (type=="metrics"){
                                  window.history.replaceState(null, "SMILEconnect-GUI", "/metrics/"+key)
                              }

                          }



                          }
                      >
                          {
                              submenu[type] && Object.keys(submenu[type]).filter(tab=>{
                                  if (window._env_.REACT_APP_DISABLED_MODULES) {
                                      const disabled=window._env_.REACT_APP_DISABLED_MODULES.split(",")
                                      return !disabled.includes(tab)
                                  }else{
                                      return true
                                  }


                              }).map((tab,i) => {

                                  let Comp = (<div/>);
                                  switch (type){
                                      case "mapping":
                                          Comp=<MappingDetails id = {'mapping-comp'} module={tab} codeView={codeView}/>;
                                          break;
                                      case "clients":
                                          Comp=<UserDetails id = {'client-comp'} module={tab} codeView={codeView}/>;
                                          break;
                                      case "edit":
                                          Comp=<Edit id = {'edit-comp'} module={tab} codeView={codeView} />;
                                          break;
                                      case "scripts":
                                          Comp=<ScriptDetails id = {'script-comp'}  />;

                                          break;
                                      case "metrics":
                                          Comp=<MetricDetails id = {'script-comp'}  />;

                                          break;
                                      default:
                                          console.log("unknown type");
                                          break;
                                  }
                                  return(
                                       <TabPane
                                          tab={submenu[type][tab].name || "a"}
                                          key={tab}
                                      >

                                          {Comp}
                                      </TabPane>
                                  )
                              })

                          }

                      </Tabs>
                  )
                  :(



                   <Tabs
                       tabPosition={'top'}
                       defaultActiveKey={module}
                       style={{ height: '100%',width:'100%' }}
                       className={"top-tabs-right"}





                   >
                       {(type=="clients") &&
                       <>
                       <TabPane key={"events"} tab={"Events"}>
                           <CodeViewer
                               value={JSON.stringify(clientHooks,0,2) }
                               onChange={(v)=>{
                                   dispatch(updateEventConf({code:true,value:JSON.parse(v)}));
                               }
                               }
                           />

                       </TabPane>
                       <TabPane key={"clients"} tab={"Config"}>
                           <CodeViewer
                               value={JSON.stringify(clientConf,0,2) }
                               onChange={(v)=>{

                                   dispatch(updateClientConf({code:true,value:JSON.parse(v)}));

                               }}
                           />

                       </TabPane>

                       </>
                       }
                       {(type=="mapping") &&
                           <>
                               <TabPane key={"mappingCustom"} tab={"Mapping Custom Forms"}>
                                   <CodeViewer
                                       value={JSON.stringify(mappingCustom,0,2) }
                                       onChange={(v)=>{
                                           dispatch(updateCustomMapping({code:true,value:JSON.parse(v)}));
                                       }}
                                   />
                               </TabPane>
                       <TabPane key={"mapping"} tab={"Mapping"}>
                           <CodeViewer
                               value={JSON.stringify(mapping,0,2) }
                               onChange={(v)=>{
                                   dispatch(updateMapping({code:true,value:JSON.parse(v)}));
                               }}
                           />
                       </TabPane>
                           </>
                       }
                   </Tabs>





                  )

              }
              {
                  (type==="scripts")&&
                  <ScriptDetails id = {'script-comp'}  />

              }
              {
                  (type==="metrics")&&
                  <MetricDetails id = {'script-comp'}  />

              }

          </PageHeader>
            </>







  );
}

export default connect(select)(Header);


