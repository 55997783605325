import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch, connect} from "react-redux";

import {Tabs, Select, Input, Form, Button, Empty, Modal, message} from "antd"
import TableFields from "../../components/TableFields";
import TableFieldsCMDB from "../../components/TableFieldsCMDB";
import TableConstants from "../../components/TableConstants";

import TreeViewScripts from "../../components/TreeViewScripts";
import BaseQuery from "../../components/BaseQuery";
import EventForm from "../../components/EventForm";
import ClientOptions from "../../components/ClientOptions";
import {getClientHooks, smileConnectAPIcall} from "../../components/util/redux/asyncActions";
import {getClient, getFormFields, getMappings, setClientConfig,getCmdbForms} from "../../components/util/redux/asyncActions";
import submenu from "../../config/submenu.json";
import {addCmdbForm,removeCmdbForm,addEvent,removeEvent} from "../../components/util/redux";
import {AuthContext} from "../../components/Auth/AuthProvider";
import {useNavigate} from "react-router-dom";
import TableFieldsCustom from "../../components/TableFieldsCustom";
import ManyosSpinner from "../../components/ManyosSpinner/ManyosSpinner";

const {confirm} = Modal;


const {TabPane} = Tabs;
const {Option} = Select;
function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }
const select = (state) => {
    return {
        mapping: state.request.mapping,
        type: state.request.type,
        loading: state.request.clientConfLoading,
        clientConf: state.request.clientConf,
        cmdbForms:state.request.cmdbForms,
        activeClient: state.request.activeClient,
        clientHooks:state.request.clientHooks,
        formFields:state.request.formFields,
    }
}

const UserDetails = (props) => {
    const {mapping, type, module, loading, clientConf, activeClient, codeView,cmdbForms,clientHooks,formFields} = props;
    const dispatch = useDispatch();
    const userManager = useContext(AuthContext);
    const navigate=useNavigate();


    const [cmdbTabs, setCmdbTabs] = React.useState(
        []
    );



    useEffect(() => {

        if (cmdbForms && cmdbForms.length==0 && module==="cmdbobject"){
            dispatch(getCmdbForms({userManager,navigate}));
        }

    }, [module]);

    useEffect(() => {

        let tabs = []
        if (clientConf && clientConf.hasOwnProperty("config")) {
            tabs = Object.keys(clientConf.config.cmdbobject).filter(c => c.indexOf("fields_") >= 0).map(config => {

                return {
                    form: config.replace(/fields_/g, ""),
                    table: "TableFieldsCMDB",
                    config: "cmdbobjects",
                    name: config.replace(/fields_/g, "") + " Fields"

                }
            })
        }
        if (JSON.stringify(tabs)!==JSON.stringify(cmdbTabs)){
            setCmdbTabs(tabs);
        }


    }, [clientConf])


    useEffect(() => {

        if (Object.keys(mapping).length === 0) {

            dispatch(getMappings({userManager,navigate}));
        }
    }, [])
    useEffect(() => {

        if (Object.keys(clientConf).length === 0) {
            dispatch(getClient({clientId: activeClient,userManager,navigate}));
        }
        if (window._env_.REACT_APP_EVENTMGR_URL && window._env_.REACT_APP_EVENTMGR_URL.length>0 && clientHooks && clientHooks.length === 0) {

            dispatch(getClientHooks({clientId: activeClient,userManager,navigate}));
        }
    }, [])


    const onEdit = (targetKey, action) => {

        let newForm;

        function callback(e) {

            newForm = e;
        }

        let newEventName;

        function callbackEvt(e){
            newEventName=e.target.value;
        }
        if (action == "add" && module=="cmdbobject") {
            confirm({
                title: "select the form",
                content: <Select
                    showSearch
                    style={{width: 300, float: "right", margin: "0 15px 15px 0"}}
                    placeholder="select a field"
                    optionFilterProp="children"
                    onSelect={callback}
                >
                    {cmdbForms.map(f=><Select.Option value={f} key={f}>{f}</Select.Option>)}
                </Select>,
                onOk: () => {
                    if (newForm && newForm.length > 0) {
                        dispatch(addCmdbForm({form:newForm}));
                    } else {
                        message.error("Please select a form name!");
                    }
                }
            })
        }



        if (action==="remove" && module ==="cmdbobject"){
            confirm({
                title: "Are you sure?",
                content: "Do you really want to remove this config?",
                onOk: () => {
                    dispatch(removeCmdbForm({form:targetKey}));
                },
                okText:"Yes",
                cancelText:"No"
            })

        }



        if (action == "add" && module=="events") {
            confirm({
                title: "Enter the new event's name",
                content: <Input onChange={callbackEvt}

                    style={{width: 300, float: "right", margin: "0 15px 15px 0"}}
                    placeholder="Enter the new event's name"

                />

               ,
                onOk: () => {
                    if (newEventName && newEventName.length > 0) {
                        dispatch(addEvent({event:newEventName}));
                    } else {
                        message.error("Please enter the name first!");
                    }
                }
            })
        }
        if (action == "remove" && module=="events") {
            confirm({
                title: "Are you sure?",
                content: "Do you really want to remove this event?",
                onOk: () => {
                    dispatch(removeEvent({event:targetKey}));
                },
                okText:"Yes",
                cancelText:"No"
            })

        }

    }


    const [selMapping, setSelMapping] = React.useState(() => {
        if (submenu[type][module].tabs.length === 0) {
            return undefined;
        }
        return submenu[type][module].tabs[0].config
    });
    const [form, setForm] = React.useState(() => {
        if (submenu[type][module].tabs.length === 0) {
            return undefined;
        }
        return submenu[type][module].tabs[0].form
    });

    const [fields, setFields] = React.useState(

    );




    useEffect(() => {
        if (!fields && form) {
            const resp = smileConnectAPIcall({
                url: window._env_.REACT_APP_API_URL + "v1/AppConfig/forms/" + form + "/fields",
                requestOptions: {method: "GET", "content-type": "application/json"},userManager,navigate
            }).then(
                f => {

                    setFields(f.data);
                }
            );

        }

    }, []);




    useEffect(() => {

if (module !=="events"){
        if (form) {


            dispatch(getFormFields({form: form,userManager,navigate}))
        } else {
            try {

                dispatch(getFormFields({form: submenu[type][module].tabs[0].form,userManager,navigate}))
            } catch (e) {
                console.log(e)
            }


        }
}

    }, [form, module, selMapping]);



    return (
        (loading)
            ? (<ManyosSpinner/>)
            : (
            (module !=="customForms")?
            <Tabs
                className={"sub-tabs-right"}
                tabPosition={'top'}
                type={(module === "cmdbobject" | module === "events") ? "editable-card" : "card"}
                defaultActiveKey={0}
                onEdit={onEdit}
                style={{height: '100%', width: '100%', backgroundColor: "white"}}
                onChange={async (key) => {

                    try {
                        setFields(undefined);

                        if (isNumber(key)){
                            if (submenu[type][module].tabs.length > 0) {
                                setSelMapping(submenu[type][module].tabs[key].config);
                                setForm(submenu[type][module].tabs[key].form);


                                  smileConnectAPIcall({
                                    url: window._env_.REACT_APP_API_URL + "v1/AppConfig/forms/" + submenu[type][module].tabs[key].form + "/fields",
                                    requestOptions: {method: "GET", "content-type": "application/json"},
                                      userManager,navigate
                                }).then(formFields=>setFields(formFields.data));


                            } else {
                                setSelMapping(undefined);
                                setForm(undefined);
                            }
                        }else{
                            setSelMapping("cmdbobject");
                            setForm(key);
                        }



                    } catch (e) {
                        console.log(e)
                    }

                }

                }
            >
                {
                    (submenu[type] && submenu[type][module] && submenu[type][module].hasOwnProperty("tabs")) &&
                    submenu[type][module].tabs.map((tab, i) => {

                        try {
                            let comp;

                            switch (tab.table) {
                                case "TableFields":
                                    comp = <TableFields selMapping={selMapping} formFields={formFields} tab={tab.table}
                                                       {...props}/>
                                    break;
                                case "TableConstants":
                                    comp = <TableConstants tab={tab.table} formFields={formFields} selMapping={selMapping}  {...props}/>
                                    break;
                                case "BaseQuery":

                                    comp = <BaseQuery key={activeClient + "-" + selMapping} tab={tab.table}
                                                      formFields={fields} form={form} config={selMapping}/>
                                    break;
                                case "ClientOptions":
                                    comp = <ClientOptions options={clientConf.config.options} {...props}/>
                                    break;
                                case "TreeViewScripts":
                                    comp = <TreeViewScripts key={activeClient + "scr-" + selMapping} tab={tab.table} {...props} config={selMapping}
                                                            scripts={tab.scripts} type={tab.type||"scripts"}/>
                                    break;
                                default:
                                    comp = <Empty/>
                            }
                            return (<TabPane
                                closable={false}
                                tab={tab.name}
                                key={i}
                            >

                                {comp}


                            </TabPane>)
                        } catch (e) {
                            console.log(e)
                        }

                    })

                }
                {((module === "cmdbobject" && cmdbTabs.length > 0) && (
                    cmdbTabs.map((tab, i) => {
                        try {
                            let comp;

                            switch (tab.table) {
                                case "TableFieldsCMDB":
                                    comp = <TableFieldsCMDB selMapping={selMapping} formFields={formFields} tab={tab.table}
                                                           {...props} form={tab.form}/>
                                    break;

                                default:
                                    comp = <Empty/>
                            }
                            return (<TabPane

                                tab={tab.name}
                                key={tab.form}

                            >

                                {comp}


                            </TabPane>)
                        } catch (e) {
                            console.log(e)
                        }
                    })
                ))}
                {((module === "events" && clientHooks && clientHooks.length > 0) && (
                    clientHooks.map((tab, i) => {


                        console.log(tab);

                            return (<TabPane

                                tab={tab.name}
                                key={tab.name}

                            >

                                <EventForm eventData={tab}/>


                            </TabPane>)

                    })
                ))}


            </Tabs>:
                ((module === "customForms" ) && (
                    <TableFieldsCustom selMapping={selMapping}
                                       {...props}  />
                ))
            )
    )
}


export default connect(select)(UserDetails);
