import {Button, Form, Input, Select,message} from "antd";
import { getFormFields} from "./util/redux/asyncActions";
import {updateMapping} from "./util/redux/index";
import {CopyOutlined}  from "@ant-design/icons";
import Table from "./ManyosTable";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import submenu from "../config/submenu.json";
import {diff} from "deep-object-diff";

const {Option} = Select;

const TableMapping=(props)=>{



    const [hasSelected,setHasSelected]=useState(false);
    const [selectedRowKeys,setSelectedRowKeys]=useState([]);



    const {addForm,selMapping,formFields,mapping,syncTo}=props


    const [sortedFields,setSortedFields]=useState([]);

    useEffect(()=>{
       if (formFields){
           setSortedFields(Object.keys(formFields).map(f=>{return {id:formFields[f].fieldId,value:formFields[f].name,label:formFields[f].name}}).sort((a, b) => (isNaN(a.label) && isNaN(b.label) ? (a.label || '').localeCompare(b.label || '') : a.label - b.label)))
       }


    },[formFields])


    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys( selectedRowKeys );
    };

    const handleSave=(record,values)=>{


        const field=formFields.find(f=>values.oldName === f.name);


        let syncVals={};
        if (field && syncTo && syncTo.toForm &&  window._env_.REACT_APP_MAPPING_SYNC!=="DISABLE"){
            const fieldToSync=syncTo.formFields.find(f=>f.fieldId === field.fieldId);
            if (fieldToSync){

                syncVals = {
                    oldName:fieldToSync.name,
                    newName:values.newName
                }
            }

        }


        const newArray=mapping[selMapping].map((row,k)=>{
            if (k==record.key){
                return values;
            }
            return row
        })

        if (Object.keys(diff(mapping[selMapping],newArray)).length>0){

            dispatch(updateMapping({module:selMapping,value:newArray}));
        }



        if (syncTo && syncTo.toMapping && syncVals && window._env_.REACT_APP_MAPPING_SYNC!=="DISABLE"){

            const newSyncedMappings=mapping[selMapping].filter(f=>{
                const mappingExists = mapping[syncTo.toMapping].find(field=>f.newName === field.newName )
                if (!mappingExists){
                    return true
                }else{
                    return false
                }
            }).map((row,k)=>{


                const field=formFields.find(f=>row.oldName === f.name);



                if (field && syncTo.toForm){
                    const fieldToSync=syncTo.formFields.find(f=>f.fieldId === field.fieldId);

                    if (fieldToSync){
                        return{
                            oldName:fieldToSync.name,
                            newName:row.newName
                        }
                    }else{
                        return {}
                    }

                }


                return row
            }).filter(f=>{
                if (f.oldName){
                    return true;
                }else{
                    return false
                }
            });

            const finaleNewMapping=[...newSyncedMappings,...mapping[syncTo.toMapping]];

            if (Object.keys(diff(mapping[syncTo.toMapping],finaleNewMapping)).length>0){
                console.log(diff(mapping[syncTo.toMapping],finaleNewMapping))
                dispatch(updateMapping({module:syncTo.toMapping,value:finaleNewMapping}))
            }
        }


    }

    useEffect(()=>{
        setHasSelected(selectedRowKeys.length > 0);
    },[selectedRowKeys])

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    function copyToClipboard(e) {
        navigator.clipboard.writeText(e)
        document.execCommand('copy');
        message.success("Copied!")
    };

    const ChangeSelect=(props)=>{
        const inputRef = useRef(null);

        const [selOptions,setSelOptions] = React.useState(sortedFields.slice(0,30));


        useEffect(() => {

            // inputRef.current.focus();

        }, []);

        return(
            <div style={{display:"flex"}}>

                    <Button  onClick={()=>copyToClipboard(props.value)}  icon={<CopyOutlined />}/>



                <Select
                    style={{float:"right"}}
                    showSearch

                    onSearch={(input)=>{
                        if (input.length>0){
                            setSelOptions(sortedFields.filter(f=>f.label.toLowerCase().indexOf(props.value.toLowerCase())>=0||f.label.toLowerCase().indexOf(input.toLowerCase()) >= 0).slice(0,30))
                        }else{
                            setSelOptions([...sortedFields.filter(f=>f.label.toLowerCase().indexOf(props.value.toLowerCase())>=0),...sortedFields.slice(0,30)])
                        }

                    }
                    }
                    filterOption={false}
                    placeholder="select a field"
                    optionFilterProp="children"

                    ref={inputRef}

                    onSelect={props.handlesave}
                    {...props}

                    options={selOptions}
                >
                    {
                        selOptions && selOptions.map((f,i)=>(
                            <Option key={"i"+f.fieldId} value={f.name}>{f.name}</Option>
                        ))
                    }

                </Select>

            </div>
        )

    }
    const ChangeText=(props)=>{
        const inputRef = useRef(null);
        useEffect(() => {

            //inputRef.current.focus();

        }, []);
        return(

            <Input placeholder="enter the api name " onMouseLeave={ props.handlesave} onBlur={props.handlesave} onPressEnter={props.handlesave} ref={inputRef} {...props} />
        )

    }






    const dispatch = useDispatch();
    return (
        <>
        <Form
            form={addForm}
            name="customized_form_controls"
            layout="inline"
            style={{marginLeft:"15px"}}
            onFinish={(values) => {


                const field=formFields.find(f=>values.oldName === f.name);

                let syncVals;
                if (field && syncTo && syncTo.toForm && window._env_.REACT_APP_MAPPING_SYNC!=="DISABLE"){
                    const fieldToSync=syncTo.formFields.find(f=>f.fieldId === field.fieldId);
                    if (fieldToSync){
                         syncVals = {
                            oldName:fieldToSync.name,
                            newName:values.newName
                        }
                    }

                }
                const newMappings=[...mapping[selMapping],values];
                dispatch(updateMapping({module:selMapping,value:newMappings}))

                if (syncTo.toMapping && syncVals && window._env_.REACT_APP_MAPPING_SYNC!=="DISABLE"){
                    const newSyncedMappings=[...mapping[syncTo.toMapping],syncVals];

                    dispatch(updateMapping({module:syncTo.toMapping,value:newSyncedMappings}))
                }
                addForm.resetFields();
            }}
            initialValues={{
                field: {
                    oldName: undefined,
                    newName: undefined,
                },
            }}
        >
            <Form.Item
                name="oldName"
                label="Form Name"
                rules={[{ required: true, message: 'Please select the Form Field!' }]}
            >
                <Select
                    showSearch
                    style={{ width: 200 ,float:"right",margin:"0 15px 15px 0"}}
                    placeholder="select a field"
                    autoClearSearchValue={false}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={sortedFields}
                >
                    {/*
                        sortedFields && sortedFields.map(f=>(
                            <Option key={f.fieldId} value={f.name}>{f.name}</Option>
                        ))
                    */}

                </Select>

            </Form.Item>
            <Form.Item
                name="newName"
                label="API Name"
                rules={[{ required: true, message: 'Please enter the API name!' }]}
            >
                <Input  placeholder="enter the api name"  />


            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Add
                </Button>
            </Form.Item>
            <Form.Item>
                <Button

                    onClick={() => {
                        addForm.resetFields();
                    }}
                >
                    Cancel
                </Button>
            </Form.Item>
        </Form>

    <Button style={{margin:"0 15px 15px 15px"}}
            disabled={!hasSelected}
            onClick={()=>{

                const newValues = mapping[selMapping].filter((r,i) => selectedRowKeys.indexOf(i)<0)

                dispatch(updateMapping({module:selMapping,value:newValues}))
                setSelectedRowKeys([]);

            }}
    >
        Delete Row
    </Button>
    <Table
        style={{padding:"0 15px 0 15px"}}
        pagination={false}

        rowSelection={rowSelection}
        dataSource={
            mapping  && mapping[selMapping] && mapping[selMapping].map((entry,i)=>{

                return {
                    key:i,
                    oldName:entry.oldName,
                    newName:entry.newName
                }}
            )

        }


        columns={
            [{title:'Form Name', dataIndex:'oldName', key:'oldName',extra:{editable:true,FormComp:ChangeSelect,handleSave:handleSave }},
                {title:'API Name', dataIndex:'newName',key:'newName',extra:{editable:true,FormComp:ChangeText,handleSave:handleSave }}]
        }
    >

    </Table>
        </>
    )
}

export default TableMapping;
