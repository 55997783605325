import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './assets/css/smileconnect.css'


import {BrowserRouter} from 'react-router-dom';
import Smileconnect from './smileconnect';
import {Provider} from "react-redux";
import store from "./components/util/redux/index";
import {AuthProvider} from "./components/Auth/AuthProvider";




ReactDOM.render(

    <Provider store={store}>
        <AuthProvider>

            <BrowserRouter>
                <Smileconnect />
            </BrowserRouter>

        </AuthProvider>
    </Provider>


, document.getElementById('root'));


