import React, {useContext, useEffect, useRef, useState} from 'react';
import {useDispatch,connect} from "react-redux";

import {Tabs, Select, Input, Form, Button, message,Modal,Popover} from "antd"
import {WarningOutlined } from "@ant-design/icons";

import Table  from "../../components/ManyosTable";
import TableMapping from "../../components/TableMapping";


import submenu from "../../config/submenu.json";

import {getFormFields, addMapping, getCmdbForms,getCustomForms} from "../../components/util/redux/asyncActions";
import {addMappingCmdbForm,removeMappingCmdbForm,addMappingCustomForm,removeMappingCustomForm} from "../../components/util/redux";

import {smileConnectAPIcall} from "../../components/util/redux/asyncActions";
import {AuthContext} from "../../components/Auth/AuthProvider";
import {useNavigate} from "react-router-dom";

import TableCustomMappings from "../../components/TableCustomMappings"
import ManyosSpinner from "../../components/ManyosSpinner/ManyosSpinner";

const {TabPane}=Tabs;
const {Option}=Select;
const {confirm}=Modal;
function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }
const select = (state)=>{
    return {
        mapping:state.request.mapping,
        type:state.request.type,
        loading:state.request.mappingLoading,
        formFields:state.request.formFields,
        cmdbForms:state.request.cmdbForms,
        customForms:state.request.customForms,
        mappingCustom:state.request.mappingCustom
    }
}
const MappingDetails = (props) =>{
    const {mapping,type,module,loading,formFields,cmdbForms,customForms,mappingCustom}=props;
    const dispatch=useDispatch();
    const userManager = useContext(AuthContext);
    const navigate=useNavigate();



    const [selMapping,setSelMapping]=React.useState((submenu[type][module].tabs[0] && submenu[type][module].tabs[0].mapping ));
    const [form,setForm]=React.useState((submenu[type][module].tabs[0] && submenu[type][module].tabs[0].form) );

    const [syncTo,setSyncTo] = React.useState({});



    useEffect(()=>{


            if (submenu[type][module].tabs[0] && submenu[type][module].tabs[0].hasOwnProperty("syncTo")){
                const toMapping = submenu[type][module].tabs[0].syncTo;
                const toConf=submenu[type][module].tabs.find(t=>t.mapping===toMapping);
                if (toConf){
                    const toForm=toConf.form;


                        const response = smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/AppConfig/forms/"+toForm+"/fields",requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate}).then(response=>{
                            setSyncTo({
                                toMapping:toMapping,
                                toForm:toForm,
                                formFields:response.data,
                            });
                        }).catch(e=>{
                            //message.error(e);
                            setSyncTo(  {
                                toMapping:undefined,
                                toForm:undefined,
                                formFields:[],
                            })
                        });











                }
                setSyncTo(  {
                    toMapping:undefined,
                    toForm:undefined,
                    formFields:[],
                })
            }else{
                setSyncTo(  {
                    toMapping:undefined,
                    toForm:undefined,
                    formFields:[],
                })
            }


    },[])

    const [addForm]=Form.useForm();


    useEffect(() => {

        if (cmdbForms && cmdbForms.length==0 && module==="cmdbobject"){
            dispatch(getCmdbForms({userManager,navigate}));
        }
        if (!customForms  && module==="customForms"){

            dispatch(getCustomForms({userManager,navigate}));
        }

    }, [module]);

    useEffect(()=>{

        dispatch(getFormFields({form:form,userManager,navigate}))

    },[module,form,type]);


    useEffect(()=>{


        if (mappingCustom && module==="customForms" && !form && !selMapping){

            setSelMapping(Object.keys(mappingCustom).length>0 && Object.keys(mappingCustom)[0])

            setForm(Object.keys(mappingCustom).length>0 && mappingCustom[Object.keys(mappingCustom)[0]].formName)
        }


    },[mappingCustom]);
    const onEdit=(targetKey,action)=>{

        let newForm;
        let alias;

        function callback(e) {

            newForm = e;
        }
        function callbackAlias(e) {

            alias = e;
        }



        if (action==="add" && module ==="cmdbobject"){
            confirm({
                title: "select the form",
                content: <Select
                    showSearch
                    style={{width: 300, float: "right", margin: "0 15px 15px 0"}}
                    placeholder="select a field"
                    optionFilterProp="children"
                    onSelect={callback}
                >
                    {cmdbForms.map(f=><Select.Option value={f} key={f}>{f}</Select.Option>)}
                </Select>,
                onOk: () => {
                    if (newForm && newForm.length > 0) {
                        dispatch(addMappingCmdbForm({form:newForm}))
                    } else {
                        message.error("Please select a form name!");
                    }
                }
            })

        }

        if (action==="remove" && module ==="cmdbobject"){
            confirm({
                title: "Are you sure?",
                content: "Do you really want to remove this mapping?",
                onOk: () => {
                    dispatch(removeMappingCmdbForm({form:targetKey}))
                },
                okText:"Yes",
                cancelText:"No"
            })

        }

        if (action==="add" && module ==="customForms"){
            confirm({
                title: "Provide details for the custom form",
                content: <><Select
                    showSearch
                    style={{width: 300, float: "right", margin: "0 15px 15px 0"}}
                    placeholder="select the form"
                    optionFilterProp="children"
                    onSelect={callback}
                >
                    {customForms && customForms.map(f=><Select.Option value={f} key={f}>{f}</Select.Option>)}
                </Select>
                <Input placeholder={"enter the form alias"} style={{width: 300, float: "right", margin: "0 15px 15px 0"}} onChange={(e)=>callbackAlias(e.target.value)}></Input>
                </>,
                onOk: () => {
                    if (alias && alias.length > 0 && newForm && newForm.length > 0) {
                        dispatch(addMappingCustomForm({form:newForm,alias}))
                    } else {
                        message.error("Please select a form name and provide alias name!");
                    }
                }
            })

        }

        if (action==="remove" && module ==="customForms"){

            confirm({
                title: "Are you sure?",
                content: "Do you really want to remove this mapping?",
                onOk: () => {
                    dispatch(removeMappingCustomForm({alias:targetKey}))
                },
                okText:"Yes",
                cancelText:"No"
            })

        }

    }


    return(
        (loading)
            ?(<ManyosSpinner/>)
            :( <Tabs
                className={"sub-tabs-right"}
                tabPosition={'top'}
                type={(module === "cmdbobject" ||module === "customForms") ? "editable-card" : "card"}
                defaultActiveKey={module}
                onEdit={onEdit}
                style={{ height: '100%',width:'100%', backgroundColor:"white" }}
                onChange={(key)=>{
                    addForm.resetFields();

                    if (!isNumber(key)){

                        setSelMapping("cmdbobject_"+key);
                        if (mappingCustom && mappingCustom[key] && mappingCustom[key].formName){
                            setForm(mappingCustom[key].formName);
                        }
                        else {setForm(key)}
                    }else{
                        setSelMapping(submenu[type][module].tabs[key].mapping)

                        if (submenu[type] && submenu[type][module] && submenu[type][module].hasOwnProperty("tabs")){
                            setForm(submenu[type][module].tabs[key].form);

                            if (submenu[type][module].tabs[key].hasOwnProperty("syncTo")){
                                //toMapping
                                const toMapping = submenu[type][module].tabs[key].syncTo;
                                const toConf=submenu[type][module].tabs.find(t=>t.mapping===toMapping);
                                if (toConf){
                                    const toForm=toConf.form;

                                        smileConnectAPIcall({url:window._env_.REACT_APP_API_URL+"v1/AppConfig/forms/"+toForm+"/fields",requestOptions:{method:"GET","content-type":"application/json"},userManager,navigate}).then(response => {
                                            setSyncTo({
                                                toMapping:toMapping,
                                                toForm:toForm,
                                                formFields:response.data,
                                            });
                                        }).catch(e => {
                                            message.error(e);
                                            setSyncTo({
                                                toMapping:undefined,
                                                toForm:undefined,
                                                formFields:[],
                                            });
                                        });





                                }else{
                                    setSyncTo({
                                        toMapping:undefined,
                                        toForm:undefined,
                                        formFields:[],
                                    });
                                }
                            }else{
                                setSyncTo({
                                    toMapping:undefined,
                                    toForm:undefined,
                                    formFields:[],
                                });
                            }

                        }

                    }

                }

                }
            >
                {
                    (submenu[type] && submenu[type][module] && submenu[type][module].hasOwnProperty("tabs")) &&
                    submenu[type][module].tabs.map((tab,i) => {
                        let missingFields=[];
                        if (window._env_.REACT_APP_MAPPING_SYNC_CHECK!=="DISABLE"){
                            if (tab.checkIn){


                                mapping[tab.checkIn].forEach(fMapping=>{
                                   const field =mapping[tab.mapping].find(e=>e.newName===fMapping.newName)
                                    if(!field){
                                        missingFields.push((<p>{fMapping.newName}</p>))
                                    }
                                })
                            }
                        }

                        return (
                            <TabPane
                                tab={
                                    (missingFields.length>0)
                                        ?(<Popover content={<div>{missingFields}</div>} title="Mapping missing for Fields">
                                            {tab.name}  <WarningOutlined/>
                                        </Popover>)
                                        :tab.name
                                }
                                key={i}
                                closeable={false}

                            >
                                <TableMapping selMapping={selMapping} addForm={addForm} formFields={formFields} syncTo={syncTo} mapping={mapping}/>
                            </TabPane>
                        )
                    }

                    )

                }
                {
                    (module==="cmdbobject" ) && mapping &&
                        Object.keys(mapping).filter(m=>m.indexOf("cmdbobject_")===0).map(tab=>{

                            return (

                            <TabPane
                                tab={tab.replace(/cmdbobject_/g,"")}
                                key={tab.replace(/cmdbobject_/g,"")}
                                closeable={true}
                            >
                                <TableMapping selMapping={selMapping} addForm={addForm} formFields={formFields} mapping={mapping}/>
                            </TabPane>
                            )}
                        )
                }
                {
                    (module==="customForms" ) && mappingCustom &&
                    Object.keys(mappingCustom).map(tab=>{

                        return (

                            <TabPane
                                tab={tab}
                                key={tab}
                                closeable={true}
                            >
                                <TableCustomMappings alias={tab} addForm={addForm} formFields={formFields} mapping={mappingCustom} customForms={customForms}/>


                            </TabPane>
                        )}
                    )
                }

            </Tabs>)
       )
}


export default connect(select)(MappingDetails);
