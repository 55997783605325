import React, {useEffect, useRef, useState} from 'react';
import {useDispatch,connect} from "react-redux";
import Editor from "@monaco-editor/react";
import {updateClientConf, updateScript} from "../../components/util/redux";
const _ = require('lodash');

function isNumber(n) { return !isNaN(parseFloat(n)) && !isNaN(n - 0) }
const select = (state)=>{
    return {
        scriptConf:state.request.scriptConf,

        loading:state.request.mappingLoading,
        formFields:state.request.formFields,
        cmdbForms:state.request.cmdbForms,
    }
}
const ScriptDetails = (props) =>{
    const dispatch = useDispatch();
    const {scriptConf}=props;
    const value="";

    const updateStore=React.useCallback(
        _.debounce(q => {
            dispatch(
                updateScript(
                    {code:q}))

        },1)

        ,[])

    return (
        <>
        {(scriptConf) &&
        <Editor
            defaultValue={scriptConf.code}
            value={scriptConf.code}

            defaultLanguage={"javascript"}
            onChange={updateStore}
            height={"100vh"}

        />
        }
        </>
    )
}


export default connect(select)(ScriptDetails);
