import {
    configureStore,
    getDefaultMiddleware,
    createSlice,

} from "@reduxjs/toolkit";



import {getClient,getClients,getMappings,setControlMetrics,removeMetric,getControlMetrics,getFormFields,addMapping,saveAction,getCmdbForms,getClientHooks,copyClient,removeClient,getScripts,getScript,addScript,removeScript,getCustomMappings,getCustomForms} from "./asyncActions";

const middleware = [
    ...getDefaultMiddleware(),
    /*YOUR CUSTOM MIDDLEWARES HERE*/
];




const requestState = {
    clients:[],
    scripts:[],
    mapping:{},
    activeClient:undefined,
    scriptConf:{},
    clientConf:{},
    loading:false,
    clientsLoading:false,
    clientConfLoading:false,
    cmdbForms:[],
    clientHooks:[],
    clientHooksLoading:false,
    mappingLoading:false,
    formFields:[],
    importMapping:{},
    importClients:[],
    unsaved:false,
    importClient:{},
    module:"mapping",
    importType:"",
    darkMode:JSON.parse(localStorage.getItem("darkMode"))||false,
    codeView:false,
    controlMetrics:[],
    newMetricConf:{}
};



const requestSlice = createSlice({
    name: "request",
    initialState: requestState,
    reducers: {

        resetState:(state)=> {
            return state = {
                clients:[],
                mapping:{},
                loading:false,
                clientConf:{},
                activeClient:undefined,
                module:"mapping",
                clientsLoading:false,
                clientHooks:[],
                scripts:[],
                cmdbForms:[],
                scriptConf:{},
                importMapping:{},
                unsaved:false,
                importClients:[],
                importClient:{},
                mappingLoading:false,
                clientConfLoading:false,
                formFields:[],
                darkMode:localStorage.getItem("darkMode")||false,
                codeView:false,
                controlMetrics: [],
                newMetricConf:{}
            };

        },
        setDarkMode:(state,action)=> {

            if (action.payload.hasOwnProperty("darkMode")){
                localStorage.setItem("darkMode",action.payload.darkMode)
                state.darkMode = action.payload.darkMode;

            }
            return state;

        },
        setNavProps:(state,action)=> {

            if (action.payload.hasOwnProperty("module")){
                state.module = action.payload.module;

            }
            if (action.payload.hasOwnProperty("type")){
                state.type = action.payload.type;

            }
            if (action.payload.hasOwnProperty("activeClient")){
                state.activeClient = action.payload.activeClient;

            }
            if (action.payload.hasOwnProperty("scriptName")){
                state.scriptName = action.payload.scriptName;

            }
            return state;

        },
        clearFormFields:(state,action)=>{
            state.formFields=[]
          return state
        },
        setCodeView:(state,action)=> {

            if (action.payload.hasOwnProperty("codeView")){
                state.codeView = action.payload.codeView;
            }
            return state;

        },
        addCmdbForm:(state,action)=>{
            if (action.payload.hasOwnProperty("form")){
                state.clientConf.config.cmdbobject["fields_"+action.payload.form]=[];
            }
            return state;
        },
        removeCmdbForm:(state,action)=>{
            if (action.payload.hasOwnProperty("form")){
                delete state.clientConf.config.cmdbobject["fields_"+action.payload.form];
                state.unsaved=true;
            }
            return state;
        },
        addCustomForm:(state,action)=>{
            if (action.payload.hasOwnProperty("form")){
                state.clientConf.config["custom_"+action.payload.form]={
                    "basequery":"1=2",
                    "fields":[],
                    "constants":[],
                    "scripts":{}
                };
                state.unsaved=true;
            }
            return state;
        },
        removeCustomForm:(state,action)=>{
            if (action.payload.hasOwnProperty("form")){
                delete state.clientConf.config["custom_"+action.payload.form]
                state.unsaved=true;
            }
            return state;
        },
        addMappingCmdbForm:(state,action)=>{
            if (action.payload.hasOwnProperty("form")){
                state.mapping["cmdbobject_"+action.payload.form]=[];
            }
            return state;
        },
        removeMappingCmdbForm:(state,action)=>{
            if (action.payload.hasOwnProperty("form")){
                delete state.mapping["cmdbobject_"+action.payload.form];
                state.unsaved=true;
            }
            return state;
        },
        addMappingCustomForm:(state,action)=>{

            if (action.payload.hasOwnProperty("form") && action.payload.hasOwnProperty("alias")){

                state.mappingCustom[action.payload.alias]={
                    "formName":action.payload.form,
                    "mapping":{}
                };
            }
            return state;
        },
        removeMappingCustomForm:(state,action)=>{
            if (action.payload.hasOwnProperty("alias")){
                delete state.mappingCustom[action.payload.alias];
                state.unsaved=true;
            }
            return state;
        },
        addEvent:(state,action)=>{
            if (action.payload.hasOwnProperty("event")){
                state.clientHooks.push({name:action.payload.event})
            }

            return state;
        },
        addMetric:(state,action)=>{
            if (action.payload.hasOwnProperty("name")){
                state.controlMetrics.push({name:action.payload.name})
            }

            return state;
        },
        removeEvent:(state,action)=>{
            if (action.payload.hasOwnProperty("event")){
                state.clientHooks=state.clientHooks.filter(h=>h.name!=action.payload.event);
            }
            state.unsaved=true;
            return state;

        },
        updateClientConf:(state,action)=>{

            if (!action.payload.hasOwnProperty("code") && action.payload.hasOwnProperty("module") && action.payload.hasOwnProperty("subConfig") && action.payload.hasOwnProperty("value")){

                state.clientConf.config[action.payload.module][action.payload.subConfig]=action.payload.value
                state.unsaved=true;

            }
            if (action.payload.hasOwnProperty("code") && action.payload.code &&  action.payload.hasOwnProperty("value")){

                state.clientConf=action.payload.value
                state.unsaved=true;

            }

            return state;
        },
        updateClientOptions :(state,action)=>{


            let dateFormat;
            if (action.payload.dateFormat && action.payload.dateFormat.length===0){
                dateFormat=undefined;
            }else{
                dateFormat =action.payload.dateFormat;
            }
            let impersonateUser;
            if (action.payload.impersonateUser && action.payload.impersonateUser.length===0){
                impersonateUser=undefined;
            }else{
                impersonateUser =action.payload.impersonateUser;
            }

                state.clientConf.config.options = {...action.payload,dateFormat,impersonateUser};
                state.unsaved = true;


            return state;
        },
        updateMapping:(state,action)=>{

            if (!action.payload.hasOwnProperty("code") && action.payload.hasOwnProperty("module") &&  action.payload.hasOwnProperty("value")){

                state.mapping[action.payload.module]=action.payload.value
                state.unsaved=true;

            }else if (action.payload.hasOwnProperty("code") && action.payload.code &&  action.payload.hasOwnProperty("value")){
                state.mapping=action.payload.value;
                state.unsaved=true;
            }



            return state;
        },
        updateMetric:(state,action)=>{
            state.unsaved=true;
            state.newMetricConf=action.payload;
            return state;
        },
        updateCustomMapping:(state,action)=>{

            if (!action.payload.hasOwnProperty("code") && action.payload.hasOwnProperty("alias") &&  action.payload.hasOwnProperty("value")){

                state.mappingCustom[action.payload.alias].mapping=action.payload.value
                state.unsaved=true;

            }else if (!action.payload.hasOwnProperty("code") && action.payload.hasOwnProperty("alias") &&  action.payload.hasOwnProperty("formName")){

                state.mappingCustom[action.payload.alias].formName=action.payload.formName
                state.unsaved=true;

            }
            else if (action.payload.hasOwnProperty("code") && action.payload.code &&  action.payload.hasOwnProperty("value")){
                state.mapping=action.payload.value;
                state.unsaved=true;
            }



            return state;
        },
        updateScript:(state,action)=>{

            if (action.payload.hasOwnProperty("code") ){

                state.scriptConf.code=action.payload.code
                state.unsaved=true;

            }



            return state;
        },
        updateEventConf:(state,action)=>{

            if (!action.payload.hasOwnProperty("code") && action.payload.hasOwnProperty("name") && action.payload.hasOwnProperty("event") && action.payload.hasOwnProperty("jsonSpace") && action.payload.hasOwnProperty("url") && action.payload.hasOwnProperty("secret")) {

                if (action.payload.name && action.payload.event && action.payload.jsonSpace && action.payload.url &&  action.payload.secret) {
                    let insecure=false;
                    if (action.payload.insecure){
                        insecure=true
                    }
                    state.clientHooks=state.clientHooks.map(e=>{
                        if (e.name === action.payload.name){
                            return {...e,...action.payload,insecure}
                        }
                        return e
                    })

                    state.unsaved=true;
                }

            }else{
                if (action.payload.hasOwnProperty("code") && action.payload.code &&  action.payload.hasOwnProperty("value")){
                    state.clientHooks=action.payload.value;
                    state.unsaved=true;
                }
            }

            return state;
        },
        updateEventScripts:(state,action)=>{

            if ( action.payload.hasOwnProperty("scripts") && action.payload.name) {

                if (action.payload.scripts && action.payload.name ) {

                    state.clientHooks=state.clientHooks.map(e=>{
                        if (e.name === action.payload.name){
                            console.log({...e,scripts:action.payload.scripts})
                            return {...e,scripts:action.payload.scripts}
                        }

                        return e
                    })

                    state.unsaved=true;
                }

            }else{
                if (action.payload.hasOwnProperty("code") && action.payload.code &&  action.payload.hasOwnProperty("value")){
                    state.clientHooks=action.payload.value;
                    state.unsaved=true;
                }
            }

            return state;
        },
        dismissSave:(state,action)=>{
            state.unsaved=false;
            state.dismissed=true;
            return state;
        },
        importFile:(state,action)=>{
            state.unsaved=true;


            if (action.payload.type==="mapping"){
                state.importMapping=action.payload.value
            }
            if (action.payload.type==="mappingCustom"){
                state.importMappingCustom=action.payload.value
            }
            if (action.payload.type==="clients"){
                state.importClients=action.payload.value
            }
            if (action.payload.type==="client"){
                state.importClient=action.payload.value
            }
            state.importType=action.payload.type;
        }

    },
    extraReducers:{
        [getClient.pending]: state => {
            state.clientConfLoading = true;
        },
        [getClient.rejected]: (state, action) => {
            state.clientConfLoading = false;

            state.error = action.error.message;
        },
        [getClient.fulfilled]: (state, action) => {
            state.clientConfLoading = false;
            state.clientConf = action.payload.data;

        },
        [getClients.pending]: state => {
            state.clientsLoading = true;
        },
        [getClients.rejected]: (state, action) => {
            state.clientsLoading = false;

            state.error = action.error.message;
        },
        [getClients.fulfilled]: (state, action) => {
            state.clientsLoading = false;
            state.clients=action.payload.data;

        },

        [getScripts.rejected]: (state, action) => {


            state.error = action.error.message;
        },
        [getScripts.fulfilled]: (state, action) => {

            state.scripts=action.payload.data;

        },
        [getScript.rejected]: (state, action) => {


            state.error = action.error.message;
        },
        [getScript.fulfilled]: (state, action) => {


            state.scriptConf=action.payload;

        },
        [addScript.rejected]: (state, action) => {


            state.error = action.error.message;
        },
        [addScript.fulfilled]: (state, action) => {


            state.scriptConf=action.payload;
            state.scriptName=action.payload.id;
            state.scripts.push(action.payload);



        },
        [getMappings.pending]: state => {
            state.mappingLoading = true;
        },
        [getMappings.rejected]: (state, action) => {
            state.mappingLoading = false;

            state.error = action.error.message;
        },
        [getMappings.fulfilled]: (state, action) => {
            state.mappingLoading = false;
            state.mapping=action.payload.data;

        },
        [getCustomMappings.pending]: state => {
            state.mappingCustomLoading = true;
        },
        [getCustomMappings.rejected]: (state, action) => {
            state.mappingCustomLoading = false;

            state.error = action.error.message;
        },
        [getCustomMappings.fulfilled]: (state, action) => {
            state.mappingCustomLoading = false;
            state.mappingCustom=action.payload.data;

        },
        [addMapping.pending]: state => {
            state.mappingLoading = true;
        },
        [addMapping.rejected]: (state, action) => {
            state.mappingLoading = false;
            state.error = action.error.message;
        },
        [addMapping.fulfilled]: (state, action) => {
            state.mappingLoading = false;
            state.mapping[action.payload.mapType]=action.payload.data;


        },
        [saveAction.pending]: state => {
            state.saveLoading = true;
        },
        [saveAction.rejected]: (state, action) => {
            state.saveLoading = false;
            state.error = action.error.message;
        },
        [saveAction.fulfilled]: (state, action) => {
            state.saveLoading = false;
            state.unsaved=false;
            state.dismissed=false;
            if (action.payload.hasOwnProperty("client")){
                state.clientConfig=action.payload.client.data;
            }
            if (action.payload.hasOwnProperty("clients")){

                state.clients=action.payload.clients;
            }
            if (action.payload.hasOwnProperty("events")){
                state.clientHooks=action.payload.events.data;
            }
            if (action.payload.hasOwnProperty("mapping")){
                state.mapping=action.payload.mapping.data;
            }
            if (action.payload.hasOwnProperty("mappingCustom")){
                state.mappingCustom=action.payload.mappingCustom.data;
            }
            if (action.payload.hasOwnProperty("scripts")){
                state.scriptConf=action.payload.scripts;
            }

        },
        [copyClient.pending]: state => {
            state.clientsLoading = true;
        },
        [copyClient.rejected]: (state, action) => {
            state.clientsLoading = false;
            state.error = action.error.message;
        },
        [copyClient.fulfilled]: (state, action) => {
            state.clientsLoading = false;
            state.unsaved=false;

            if (action.payload.hasOwnProperty("data")){
                state.clients.push(action.payload.data);
            }
            return state;

        },
        [removeClient.pending]: state => {
            state.clientsLoading = true;
        },
        [removeClient.rejected]: (state, action) => {
            state.clientsLoading = false;
            state.error = action.error.message;
        },
        [removeClient.fulfilled]: (state, action) => {
            state.clientsLoading = false;
            state.unsaved=false;


            state.clients=  state.clients.filter(c=>c.name!==action.payload);

            return state;

        },

        [removeScript.rejected]: (state, action) => {

            state.error = action.error.message;
        },
        [removeScript.fulfilled]: (state, action) => {




            state.scripts=  state.scripts.filter(c=>c.id!==action.payload.id);

            return state;

        },
        [getCmdbForms.pending]: state => {
            state.cmdbFormsLoading = true;
        },
        [getCmdbForms.rejected]: (state, action) => {
            state.cmdbFormsLoading = false;
            state.error = action.error.message;
        },
        [getCmdbForms.fulfilled]: (state, action) => {
            state.cmdbFormsLoading = false;
            state.cmdbForms=action.payload;


        },
        [getCustomForms.pending]: state => {
            state.customFormsLoading = true;
        },
        [getCustomForms.rejected]: (state, action) => {
            state.customFormsLoading = false;
            state.error = action.error.message;
        },
        [getCustomForms.fulfilled]: (state, action) => {
            state.customFormsLoading = false;
            state.customForms=action.payload;


        },
        [getFormFields.pending]: state => {
           return state
        },
        [getFormFields.rejected]: (state, action) => {


            state.error = action.error.message;
        },
        [getFormFields.fulfilled]: (state, action) => {

            if (action.payload && action.payload.data){
                state.formFields=action.payload.data;
            }



        },
        [getClientHooks.pending]: state => {
            state.clientHooksLoading=true;

        },
        [getClientHooks.rejected]: (state, action) => {

            state.error = action.error.message;
            state.clientHooksLoading=false;

        },
        [getClientHooks.fulfilled]: (state, action) => {

            state.clientHooks=action.payload;
            state.clientHooksLoading=false;


        },
        [getControlMetrics.pending]: state => {
            state.controlMetricsLoading=true;

        },
        [getControlMetrics.rejected]: (state, action) => {

            state.error = action.error.message;
            state.controlMetricsLoading=false;

        },
        [getControlMetrics.fulfilled]: (state, action) => {

            state.controlMetrics=action.payload;
            state.controlMetricsLoading=false;


        },
        [setControlMetrics.pending]: state => {
            state.controlMetricsLoading=true;

        },
        [setControlMetrics.rejected]: (state, action) => {

            state.error = action.error.message;
            state.controlMetricsLoading=false;

        },
        [setControlMetrics.fulfilled]: (state, action) => {
            state.unsaved=false;
            state.controlMetricsLoading=false;
            state.controlMetrics=action.payload;


        },
        [removeMetric.pending]: state => {
            state.controlMetricsLoading=true;

        },
        [removeMetric.rejected]: (state, action) => {

            state.error = action.error.message;
            state.controlMetricsLoading=false;

        },
        [removeMetric.fulfilled]: (state, action) => {
            state.unsaved=false;
            state.controlMetricsLoading=false;
            state.controlMetrics=action.payload;


        }

    }
});

export const { addMetric,resetState,setDarkMode,updateMetric,setCodeView,clearFormFields,setNavProps,addCmdbForm,removeCmdbForm,addEvent,removeEvent,addMappingCmdbForm,removeMappingCmdbForm,addMappingCustomForm,removeMappingCustomForm,updateClientConf,updateEventConf,updateMapping,dismissSave,importFile,updateClientOptions ,updateScript,updateEventScripts,updateCustomMapping,removeCustomForm,addCustomForm} = requestSlice.actions;

const requestReducer = requestSlice.reducer;

export default configureStore({
    reducer: {
        request: requestReducer,
    },
    middleware,
});



