import React, {useEffect, useState} from 'react';
import {connect,useDispatch} from "react-redux";


import Header from "../Header";
import { useParams} from "react-router-dom";

import {setNavProps} from "../../components/util/redux";



const select = (state)=>{
    return {
        mapping:state.request.mapping,
        type:state.request.type,
        loading:state.request.mappingLoading,

    }
}
const EditConf = (props) =>{


    const { module } = useParams();
    const [ready,setReady] = useState(false);
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(setNavProps({module:module,type:"edit",activeClient:undefined}))
        setReady(true);
    },[module])



    return (
        <div>
            {
                ready && (<Header id={'header-comp'} type="edit" module={module}/>)
            }

        </div>

    )
}

export default connect(select)(EditConf)

