import {Button, Form, Input, Select} from "antd";

import {updateCustomMapping} from "./util/redux/index";
import Table from "./ManyosTable";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {diff} from "deep-object-diff";


const {Option} = Select;

const TableCustomMappings=(props)=>{



    const [hasSelected,setHasSelected]=useState(false);
    const [selectedRowKeys,setSelectedRowKeys]=useState([]);



    const {addForm,alias,formFields,mapping,customForms}=props


    const [sortedFields,setSortedFields]=useState([]);

    useEffect(()=>{
        if (formFields){
            setSortedFields(Object.keys(formFields).map(f=>{return {id:formFields[f].fieldId,value:formFields[f].name,label:formFields[f].name}}).sort((a, b) => (isNaN(a.label) && isNaN(b.label) ? (a.label || '').localeCompare(b.label || '') : a.label - b.label)))
        }


    },[formFields])


    const onSelectChange = selectedRowKeys => {
        setSelectedRowKeys( selectedRowKeys );
    };

    const handleSave=(record,values)=>{


        const field=formFields.find(f=>values.oldName === f.name);





        let newMapping={...mapping[alias].mapping,[values.oldName]:values.newName};

        if (Object.keys(mapping[alias].mapping)[record.key]!==values.oldName){
            delete newMapping[Object.keys(mapping[alias].mapping)[record.key]]
        }

        if (Object.keys(diff(mapping[alias].mapping,newMapping)).length>0) {
            dispatch(updateCustomMapping({alias, value: newMapping}));
        }




    }

    useEffect(()=>{
        setHasSelected(selectedRowKeys.length > 0);
    },[selectedRowKeys])

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };



    const ChangeSelect=(props)=>{
        const inputRef = useRef(null);

        const [selOptions,setSelOptions] = React.useState(sortedFields.slice(0,30));


        useEffect(() => {

            // inputRef.current.focus();

        }, []);

        return(

            <Select
                showSearch

                onSearch={(input)=>{
                    if (input.length>0){
                        setSelOptions(sortedFields.filter(f=>f.label.toLowerCase().indexOf(props.value.toLowerCase())>=0||f.label.toLowerCase().indexOf(input.toLowerCase()) >= 0).slice(0,30))
                    }else{
                        setSelOptions([...sortedFields.filter(f=>f.label.toLowerCase().indexOf(props.value.toLowerCase())>=0),...sortedFields.slice(0,30)])
                    }

                }
                }
                filterOption={false}
                placeholder="select a field"
                optionFilterProp="children"

                ref={inputRef}

                onSelect={props.handlesave}
                {...props}
                options={selOptions}
            >
                {
                    selOptions && selOptions.map(f=>(
                        <Option key={f.fieldId} value={f.name}>{f.name}</Option>
                    ))
                }

            </Select>
        )

    }
    const ChangeText=(props)=>{
        const inputRef = useRef(null);
        useEffect(() => {

            //inputRef.current.focus();

        }, []);
        return(

            <Input placeholder="enter the api name " onMouseLeave={ props.handlesave} onBlur={props.handlesave} onPressEnter={props.handlesave} ref={inputRef} {...props} />
        )

    }






    const dispatch = useDispatch();
    return (
        <>
           <Form.Item label={"Form Name"} style={{float:"right",margin:"0 15px 15px 0"}}>

               <Select
                   showSearch
                   style={{ width: 200 ,float:"right"}}
                   placeholder="select the form name"
                   optionFilterProp="children"
                   value={mapping[alias].formName}
                   onChange={(e)=>{
                       dispatch(updateCustomMapping({alias,formName:e}));
                   }}
                   filterOption={(input, option) =>
                       option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                   }
                   options={customForms && customForms.map(e=>{return {value:e,label:e}})}
               />
           </Form.Item>
            <Form
                form={addForm}
                name="customized_form_controls"
                layout="inline"
                style={{marginLeft:"15px"}}
                onFinish={(values) => {


                    const field=formFields.find(f=>values.oldName === f.name);



                    const newMappings={...mapping[alias].mapping,[values.oldName]:values.newName};
                    dispatch(updateCustomMapping({alias,value:newMappings}))


                    addForm.resetFields();

                }}
                initialValues={{
                    field: {
                        oldName: undefined,
                        newName: undefined,
                    },
                }}
            >
                <Form.Item
                    name="oldName"
                    label="Field Name"
                    rules={[{ required: true, message: 'Please select the Form Field!' }]}
                >
                    <Select
                        showSearch
                        style={{ width: 200 ,float:"right",margin:"0 15px 15px 0"}}
                        placeholder="select a field"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        options={sortedFields}
                    >
                        {/*
                        sortedFields && sortedFields.map(f=>(
                            <Option key={f.fieldId} value={f.name}>{f.name}</Option>
                        ))
                    */}

                    </Select>
                </Form.Item>
                <Form.Item
                    name="newName"
                    label="API Name"
                    rules={[{ required: true, message: 'Please enter the API name!' }]}
                >
                    <Input  placeholder="enter the api name"  />


                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button

                        onClick={() => {
                            addForm.resetFields();
                        }}
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </Form>

            <Button style={{margin:"0 15px 15px 15px"}}
                    disabled={!hasSelected}
                    onClick={()=>{
                        let newValues={...mapping[alias].mapping}
                        selectedRowKeys.forEach(i=>{
                            delete newValues[Object.keys(mapping[alias].mapping)[i]]
                        })



                        dispatch(updateCustomMapping({alias,value:newValues}))
                        setSelectedRowKeys([]);

                    }}
            >
                Delete Row
            </Button>
            <Table
                style={{padding:"0 15px 0 15px"}}
                pagination={false}

                rowSelection={rowSelection}
                dataSource={
                    mapping  && mapping[alias] && mapping[alias] && mapping[alias].mapping && Object.keys(mapping[alias].mapping).map((entry,i)=>{

                        return {
                            key:i,
                            oldName:entry,
                            newName:mapping[alias].mapping[entry]
                        }}
                    )

                }


                columns={
                    [{title:'Form Name', dataIndex:'oldName', key:'oldName',extra:{editable:true,FormComp:ChangeSelect,handleSave:handleSave }},
                        {title:'API Name', dataIndex:'newName',key:'newName',extra:{editable:true,FormComp:ChangeText,handleSave:handleSave }}]
                }
            >

            </Table>
        </>
    )
}

export default TableCustomMappings;
