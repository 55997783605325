import React from 'react';


import {Form,Input,Switch,InputNumber,Transfer,Select} from 'antd';
import {updateClientConf, updateEventConf,updateEventScripts} from './util/redux/index';
import {connect, useDispatch} from "react-redux";

const Option = {Select};

const select = state => {

    let scripts;

    if ( state && state.hasOwnProperty("request") && state.request.hasOwnProperty("scripts") ){
        scripts=state.request.scripts.map(c=>c.id).sort((a, b) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b))
    }else {
        scripts=state.request.scripts
    }

    return {
        darkMode: state.request.darkMode,
        activeClient: state.request.activeClient,
        clientConf: state.request.clientConf,
        rScripts:scripts,
    };
};

const EventForm = ( props )=>{
    const dispatch=useDispatch();

    console.log(props.rScripts);
    const {eventData}=props;

    const [selScripts,setSelScripts]=React.useState(

[]
    );

    const [showAuth,setShowAuth]=React.useState(

        ()=>{
            if (eventData.auth){
                return true
            }else{
                return false
            }
        }
    );
    React.useEffect(
        ()=>{



            if (eventData.scripts && Array.isArray(eventData.scripts)){
                let tmp=[];
                eventData.scripts.forEach(script=>{

                    const i=props.rScripts.indexOf(script);

                    if (i>-1){
                        tmp.push(i);
                    }

                });

                setSelScripts(tmp) ;

            }else{
                setSelScripts([]) ;
            }



    },[props.rScripts])
    const onValuesChange=(changedValues,allValues)=>{

            dispatch(updateEventConf({...allValues}));
    }

    const authChange=(changedValues,allValues)=>{

        if (allValues.authEnable){
            if (allValues.type &&  allValues.user && allValues.user.length >0 && allValues.password && allValues.password.length>0){
                const {authEnable,...finValues}=allValues;

                dispatch(updateEventConf({...eventData,auth:finValues}));
            }
        }else{
            dispatch(updateEventConf({...eventData,auth:undefined}));

        }


    }

    const filterOption = (inputValue, option) => option.title.indexOf(inputValue) > -1;

    const handleChange = targetKeys => {


        setSelScripts(targetKeys)

        // set in script config

        dispatch(updateEventScripts({name:eventData.name,scripts:targetKeys.map(k=>props.rScripts[k])}))


    };
    const handleSearch = (dir, value) => {
        console.log('search:', dir, value);
    };
    return (
        <>
        <Form
            style={{margin:"20px"}}
            name="basic"
            initialValues={eventData}

            onValuesChange={onValuesChange}
        >
            <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please enter the name of this Event!' }]}

            >
                <Input  disabled={true}/>
            </Form.Item>
            <Form.Item
                label="Event"
                name="event"
                rules={[{ required: true, message: 'Please enter the name of the Event. For Example: INC*' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Insecure"
                name="insecure"
                valuePropName="checked"
                rules={[{ required: false}]}
            >
                <Switch />
            </Form.Item>
            <Form.Item
                label="Json Space"
                name="jsonSpace"
                rules={[{ required: true, message: 'Please enter the Json Space for the Event Body. For Example:2' }]}
            >
                <InputNumber min={0} />
            </Form.Item>

            <Form.Item
                label="URL"
                name="url"
                rules={[{ required: true, message: 'Please input the URL for the Webhook' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Secret"
                name="secret"
                rules={[{ required: true, message: 'Please input the webhook secret' }]}
            >
                <Input.Password />
            </Form.Item>



        </Form>

            <Form  key="auth" style={{margin:"20px"}}
                   name="auth"
                   onValuesChange={authChange}
                   initialValues={{...eventData.auth,authEnable:showAuth}}
            >

                <Form.Item
                    label="Authentication"
                    valuePropName={"checked"}
                    name="authEnable"
                >
                    <Switch onChange={val=>setShowAuth(val)} checked={showAuth}/>
                </Form.Item>

                {(showAuth) && (
                    <>
                <Form.Item
                    label="Type"
                    name="type"

                    rules={[{ required: showAuth, message: 'Please select the auth type' }]}
                >
                    <Select><Select.Option key={"basic"} value={"basic"}>basic</Select.Option></Select>
                </Form.Item>
                <Form.Item
                    label="User"
                    name="user"
                    rules={[{ required: showAuth , message: 'Please input the auth user' }]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: showAuth, message: 'Please input the auth password' }]}
                >
                    <Input.Password />
                </Form.Item>
                    </>
                )}


            </Form>
            {(props.rScripts && props.rScripts.length>0  ) &&(
        <div style={{marginLeft: "25px"}}>
            <div>Scripts</div>
        <Transfer
            dataSource={props.rScripts.map((i,k)=>{return {key:k,title:i}})}
            showSearch
            listStyle={{
                width: 501,
                height: 500,

            }}
            titles={['All Scripts', 'Selected Scripts']}
            filterOption={filterOption}
            targetKeys={selScripts}
            onChange={handleChange}
            onSearch={handleSearch}
            render={item => item.title}
        /></div>)}
        </>


    )

}


export default connect(select)(EventForm)
