import React from 'react';
import RemedyEditor from './util/monaco-remedy';
import {useDispatch, connect} from "react-redux";
import {updateClientConf} from './util/redux/index'
const _ = require('lodash');
const select = (state)=>{
    return {
        mapping:state.request.mapping,
        type:state.request.type,
        module:state.request.module,
        loading:state.request.mappingLoading,

        clientConf:state.request.clientConf,
        client:state.request.activeClient
    }
}
const BaseQuery=(props)=> {

    const {clientConf,module,config,client,formFields,form}=props;
    const [baseQuery,setBaseQuery]=React.useState(undefined)

    const dispatch=useDispatch();
    React.useEffect(()=>{

        if (clientConf  && config && clientConf.hasOwnProperty("config") && clientConf.config.hasOwnProperty(config) && clientConf.config[config].hasOwnProperty("basequery")){

            setBaseQuery(clientConf.config[config].basequery);
        }
    },[clientConf,config]);





    const updateStore=React.useCallback(
        _.debounce(q => {

            dispatch(
                updateClientConf(
                    {module:config,subConfig:"basequery",value:q}))

        },500)

        ,[config])







   return ( <RemedyEditor
       key={module+"-bq-editor"}
        height={"50vh"}
        config={config}
        defaultValue={baseQuery }
        formFields={formFields}
        client={client}
       form={form}
       onChange={updateStore}
           //setConfig(JSON.parse(config))}
    />)
}

export default connect(select)(BaseQuery);
