import {Button, Form, Input, Select} from "antd";
import {setClientConfig} from "./util/redux/asyncActions";
import Table from "./ManyosTable";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {updateClientConf} from "./util/redux";
import {useHistory} from "react-router-dom";
import {diff} from "deep-object-diff";

const {Option} = Select;

const TableConstants = (props) => {
    const {mapping, type, module, loading, formFields, clientConf, activeClient,selMapping} = props;
    const dispatch=useDispatch();

    const [hasSelected, setHasSelected] = useState(false);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [addForm] = Form.useForm();

    const [sortedFields,setSortedFields]=useState([]);

    useEffect(()=>{
        if (formFields){
            setSortedFields(Object.keys(formFields).map(f=>{return {id:formFields[f].fieldId,value:formFields[f].name,label:formFields[f].name}}).sort((a, b) => (isNaN(a.label) && isNaN(b.label) ? (a.label || '').localeCompare(b.label || '') : a.label - b.label)))
        }


    },[formFields])
    const onSelectChange = selectedRowKeys => {

        setSelectedRowKeys(selectedRowKeys);
    };


    const handleSave = (record, values) => {





        let newArray = clientConf.config[selMapping].constants.map((row, k) => {
            if (k == record.key) {

                return {name:values.fieldName,value:values.value} ;
            }

            return row
        })

        if (Object.keys(diff(clientConf.config[selMapping].constants,newArray)).length>0) {
            dispatch(updateClientConf({module: selMapping, subConfig: "constants", value: newArray}));
        }
    }

    useEffect(() => {
        setHasSelected(selectedRowKeys.length > 0);
    }, [selectedRowKeys])

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };


    const ChangeSelect = (props) => {
        const inputRef = useRef(null);
        const [selOptions,setSelOptions] = React.useState(sortedFields.slice(0,30));
        return (

                <Select
                    showSearch

                    placeholder="select a field"
                    optionFilterProp="children"
                    onSearch={(input)=>{
                        if (input.length>0){
                            setSelOptions(sortedFields.filter(f=>f.label.toLowerCase().indexOf(props.value.toLowerCase())>=0||f.label.toLowerCase().indexOf(input.toLowerCase()) >= 0).slice(0,30))
                        }else{
                            setSelOptions([...sortedFields.filter(f=>f.label.toLowerCase().indexOf(props.value.toLowerCase())>=0),...sortedFields.slice(0,30)])
                        }

                    }
                    }
                    filterOption={false}

                    ref={inputRef}
                    onSelect={props.handlesave}
                    {...props}
                    options={selOptions}
                >
                    {/*
                        formFields &&
                        Object.keys(formFields).map(f => {
                            return {fieldId: formFields[f].fieldId, name: formFields[f].name}
                        }).sort((a, b) => (isNaN(a.name) && isNaN(b.name) ? (a.name || '').localeCompare(b.name || '') : a.name - b.name)).map(f => (
                            <Option key={f.fieldId} value={f.name}>{f.name}</Option>
                        ))
                    */}

                </Select>)

    }
    const ChangeText = (props) => {
        const inputRef = useRef(null);

        return (

                <Input placeholder="enter the value " onMouseLeave={props.handlesave} onBlur={props.handlesave} ref={inputRef} {...props} />
           )

    }

    return (
        <>
            <Form
                form={addForm}
                name="customized_form_controls"
                layout="inline"
                style={{marginLeft: "15px"}}
                onFinish={(values) => {

                    const newFields=[...clientConf.config[selMapping].constants];
                    newFields.push({name:values.fieldName,value:values.value});
                    dispatch(updateClientConf({module:selMapping,subConfig:"constants",value:newFields}));


                    addForm.resetFields();
                }}
                initialValues={{
                    field: {
                        fieldName: undefined,

                    },
                }}
            >
                <Form.Item
                    name="fieldName"
                    label="Form Name"
                    rules={[{required: true, message: 'Please select the Form Field!'}]}
                >
                    <Select
                        showSearch
                        style={{width: 500, float: "right", margin: "0 15px 15px 0"}}
                        placeholder="select a field"
                        optionFilterProp="children"


                    >
                        {
                            formFields &&
                            Object.keys(formFields).map(f => {

                                return {fieldId: formFields[f].fieldId, name: formFields[f].name}
                            }).sort((a, b) => (isNaN(a.name) && isNaN(b.name) ? (a.name || '').localeCompare(b.name || '') : a.name - b.name)).map(f =>
                                (                                <Option key={f.fieldId} value={f.name}>{f.name } </Option>)
                            )
                        }

                    </Select>
                </Form.Item>
                <Form.Item
                    name="value"
                    label="Value"
                    rules={[{ required: true, message: 'Please enter the value !' }]}
                >
                    <Input  placeholder="enter the value "  />


                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Add
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button

                        onClick={() => {
                            addForm.resetFields();
                        }}
                    >
                        Cancel
                    </Button>
                </Form.Item>
            </Form>

            <Button style={{margin: "0 15px 15px 15px"}}
                    disabled={!hasSelected}
                    onClick={() => {

                        const newValues = clientConf.config[selMapping].constants.filter((r, i) => selectedRowKeys.indexOf(i) < 0)

                        dispatch(updateClientConf({
                            module: selMapping,
                            subConfig:"constants",
                            value: newValues
                        }));
                        setSelectedRowKeys([]);

                    }}
            >
                Delete Row
            </Button>
            <Table
                style={{padding: "0 15px 0 15px"}}
                pagination={false}

                rowSelection={rowSelection}
                dataSource={
                    clientConf && clientConf.config && clientConf.config[selMapping] && clientConf.config[selMapping].constants.map((entry, i) => {




                            return {
                                key: i,
                                fieldName: entry.name,
                                value: entry.value
                            }
                        }
                    )

                }


                columns={
                    [{
                        title: 'Form Name',
                        dataIndex: 'fieldName',
                        key: 'fieldName',
                        extra: {editable: true, FormComp: ChangeSelect, handleSave: handleSave}
                    },
                        {title: 'value', dataIndex: 'value', key: 'value',extra: {editable: true, FormComp: ChangeText, handleSave: handleSave}}]
                }
            >

            </Table>
        </>)
}
export default TableConstants
